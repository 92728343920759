import { P2Span } from "@constellation-academy/epic-ui";
import { Icon } from "@components/icon";
import { cardClass } from "./unlock-wall-item.styles";

//TODO: add-translations
export const UnlockWallItem = () => {
	return (
		<div className={cardClass}>
			<Icon icon="loginKey" sizeInRem={3} />
			<P2Span>Um diesen Kurs abschließen zu können, wird eine Lizenz benötigt.</P2Span>
		</div>
	);
};
