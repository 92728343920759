import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { selectUserInAccountGroupsField_Query } from "@relay/selectUserInAccountGroupsField_Query.graphql";
import { QUERY } from "./select-user-in-account-groups-field.graphql";
import { selectClass } from "./select-user-in-account-groups-field.styles";
import { SelectUserInAccountGroupsFieldProps } from "./select-user-in-account-groups-field.types";
import { convertGroupName } from "./select-user-in-account-groups-field.util";

export const SelectUserInAccountGroupsField = ({
	value,
	placeholder,
	onChange,
	singleSelection,
	accountId,
}: SelectUserInAccountGroupsFieldProps) => {
	const groups = useLazyLoadQuery<selectUserInAccountGroupsField_Query>(QUERY, {
		filterByAccountId: accountId,
	});

	const options = useMemo(
		() =>
			[...groups.Auth.SearchUserInAccountGroupsAsAccountOwnerQuery]?.map((group) => ({
				label: convertGroupName(group.name),
				value: group.id,
			})),
		[groups],
	);

	const handleOnChange = (event: MultiSelectChangeEvent) => {
		onChange(event.value);
	};

	return (
		<MultiSelect
			className={selectClass}
			placeholder={placeholder}
			value={value}
			options={options}
			onChange={handleOnChange}
			filter
			showSelectAll={!singleSelection}
			filterBy={"label"}
			selectionLimit={singleSelection ? 1 : undefined}
		/>
	);
};
