import { PathParams } from "@constellation-academy/epic-ui";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { Card } from "@components/card";
import { Checkbox } from "@components/checkbox";
import { InputText } from "@components/input-text";
import { checkboxQuestion_PotentialAnalysisQuestionFragment$key } from "@relay/checkboxQuestion_PotentialAnalysisQuestionFragment.graphql";
import { checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation } from "@relay/checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation.graphql";
import { checkboxQuestion_SetAnswerInMultipleChoiceQuestionMutation } from "@relay/checkboxQuestion_SetAnswerInMultipleChoiceQuestionMutation.graphql";
import { checkboxQuestion_SetAnswerInSingleChoiceFreeTextQuestionMutation } from "@relay/checkboxQuestion_SetAnswerInSingleChoiceFreeTextQuestionMutation.graphql";
import { checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation } from "@relay/checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation.graphql";
import { AvgsPotentialAnalysisPath } from "@router/paths";
import { colorShade100Class } from "@themes/color-classes";
import { H3Span, P2Span } from "@themes/font-tags";
import { indexToAlpha } from "@utils/alphabet";
import { stripHtml } from "@utils/strip-html";
import {
	POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
	SET_ANSWER_IN_MULTIPLE_CHOICE_FREE_TEXT_QUESTION_MUTATION,
	SET_ANSWER_IN_MULTIPLE_CHOICE_QUESTION_MUTATION,
	SET_ANSWER_IN_SINGLE_CHOICE_FREE_TEXT_QUESTION_MUTATION,
	SET_ANSWER_IN_SINGLE_CHOICE_QUESTION_MUTATION,
} from "./checkbox-question.graphql";
import {
	cardContentClass,
	inputWrapperClass,
	labelClass,
	wrapperClass,
} from "./checkbox-question.styles";
import { CheckboxQuestionProps } from "./checkbox-question.types";
import { isQuestionAnswered } from "../avgs-potential-analysis-navigation/avgs-potential-analysis-navigation.utils";
export const CheckboxQuestion = ({
	isActive,
	isRadio,
	potentialAnalysisQuestionFragmentRef,
}: CheckboxQuestionProps) => {
	const { potentialAnalysisId } = useParams<PathParams<typeof AvgsPotentialAnalysisPath>>();

	const question = useFragment<checkboxQuestion_PotentialAnalysisQuestionFragment$key>(
		POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
		potentialAnalysisQuestionFragmentRef,
	);

	const { id, kind, questionText, answerOptions, givenAnswer, givenAnswers } = question;

	const [setAnswerInSingleChoiceQuestion] =
		useMutation<checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation>(
			SET_ANSWER_IN_SINGLE_CHOICE_QUESTION_MUTATION,
		);
	const [setAnswerInSingleChoiceFreeTextQuestion] =
		useMutation<checkboxQuestion_SetAnswerInSingleChoiceFreeTextQuestionMutation>(
			SET_ANSWER_IN_SINGLE_CHOICE_FREE_TEXT_QUESTION_MUTATION,
		);

	const [setAnswerInMultipleChoiceQuestion] =
		useMutation<checkboxQuestion_SetAnswerInMultipleChoiceQuestionMutation>(
			SET_ANSWER_IN_MULTIPLE_CHOICE_QUESTION_MUTATION,
		);
	const [setAnswerInMultipleChoiceFreeTextQuestion] =
		useMutation<checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation>(
			SET_ANSWER_IN_MULTIPLE_CHOICE_FREE_TEXT_QUESTION_MUTATION,
		);

	const freeText = (givenAnswers ?? (givenAnswer ? [givenAnswer] : []))?.find(
		(answer) => answer?.freeText,
	)?.freeText;
	const [textInput, setTextInput] = useState<string>(freeText ?? "");

	const initialState =
		givenAnswers?.map((a) => a.answerOptionId) ??
		(givenAnswer?.answerOptionId ? [givenAnswer.answerOptionId] : []);
	const [checkedAnswerOptionIds, setCheckedAnswerOptionIds] = useState<number[]>(initialState);
	const useRadio = isRadio;

	const isAnswered = isQuestionAnswered(question);
	const isBlured = !isAnswered && !isActive;

	const updateAnswer = (updateAnswerIds: number[], text?: string) => {
		const answerOptionsWritable = question.answerOptions?.slice();
		if (!potentialAnalysisId || !answerOptionsWritable || !questionText) return;

		const freeTextAnswerId = answerOptionsWritable.find(
			(option) => option.isFreeText,
		)?.answerOptionId;

		if (useRadio) {
			const firstAnswerId = updateAnswerIds[0];
			if (!firstAnswerId) return;
			const input = {
				questionId: id,
				answerOptions: answerOptionsWritable,
				questionText: questionText,
				givenAnswer: {
					answerOptionId: firstAnswerId,
				},
				learnPotentialAnalysisId: potentialAnalysisId,
			};

			if (kind === "SINGLE_CHOICE") {
				setAnswerInSingleChoiceQuestion({
					variables: {
						input,
					},
				});
			} else if (kind === "SINGLE_CHOICE_FREETEXT") {
				setAnswerInSingleChoiceFreeTextQuestion({
					variables: {
						input: {
							...input,
							givenAnswer: {
								...input.givenAnswer,
								text: text ?? textInput,
							},
						},
					},
				});
			}
		} else {
			const input = {
				questionId: id,
				answerOptions: answerOptionsWritable,
				questionText: questionText,
				givenAnswer: updateAnswerIds.map((answerOptionId) => ({
					answerOptionId,
				})),
				learnPotentialAnalysisId: potentialAnalysisId,
			};

			if (kind === "MULTIPLE_CHOICE") {
				setAnswerInMultipleChoiceQuestion({
					variables: {
						input,
					},
				});
			} else if (kind === "MULTIPLE_CHOICE_FREETEXT") {
				setAnswerInMultipleChoiceFreeTextQuestion({
					variables: {
						input: {
							...input,
							givenAnswer: updateAnswerIds.map((answerOptionId) => ({
								answerOptionId,
								text: freeTextAnswerId === answerOptionId ? text ?? textInput : "",
							})),
						},
					},
				});
			}
		}
	};

	const createCheckboxOnChangeHandler = (changedAnswerOptionId: number) => () => {
		if (useRadio) {
			setCheckedAnswerOptionIds([changedAnswerOptionId]);
			updateAnswer([changedAnswerOptionId]);
		} else {
			const isSelected = checkedAnswerOptionIds.includes(changedAnswerOptionId);

			const newAnswerOptionIds = match(isSelected)
				.with(true, () => checkedAnswerOptionIds.filter((i) => i !== changedAnswerOptionId))
				.with(false, () => [...checkedAnswerOptionIds, changedAnswerOptionId])
				.exhaustive();

			setCheckedAnswerOptionIds(newAnswerOptionIds);
			updateAnswer(newAnswerOptionIds);
		}
	};

	const debounceUpdateAnswer = useMemo(
		() =>
			_.debounce((ids: number[], text?: string) => {
				updateAnswer(ids, text);
			}, 500),
		[],
	);

	const createOnTextChangeHandler = (checked: boolean) => (text?: string) => {
		setTextInput(text ?? "");
		if (checked) {
			debounceUpdateAnswer(checkedAnswerOptionIds, text);
		}
	};

	return (
		<div className={wrapperClass({ isBlured })}>
			{answerOptions?.map((answerOption, index) => {
				const checked = checkedAnswerOptionIds.includes(answerOption.answerOptionId);
				const isFreeText = answerOption.isFreeText;
				return (
					<Card key={answerOption.answerOptionId} bodyPaddingInRem={0}>
						<div className={cardContentClass}>
							<H3Span className={colorShade100Class}>{indexToAlpha(index)}</H3Span>
							{isFreeText ? (
								<div className={inputWrapperClass}>
									<InputText
										value={textInput}
										placeholder="Sonstiges ..."
										onChange={createOnTextChangeHandler(checked)}
									/>
								</div>
							) : (
								<P2Span className={labelClass}>
									{stripHtml(answerOption.text ?? "")}
								</P2Span>
							)}
							<Checkbox
								checked={checked}
								onChange={createCheckboxOnChangeHandler(
									answerOption.answerOptionId,
								)}
							/>
						</div>
					</Card>
				);
			})}
		</div>
	);
};
