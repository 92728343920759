import { MouseEventHandler, useContext, useRef } from "react";
import { useFragment, useQueryLoader } from "react-relay";
import { Avatar } from "@components/avatar";
import { DonutProgressChart } from "@components/donut-progress-chart";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { ExpandableBox } from "@components/expandable-box";
import { Icon } from "@components/icon";
import { OverlayPanel } from "@components/overlay-panel";
import { learnTrackingRow_LearnStatesForUserFragment$key } from "@relay/learnTrackingRow_LearnStatesForUserFragment.graphql";
import { learnTrackingRow_Query } from "@relay/learnTrackingRow_Query.graphql";
import { LearnTrackingTabContext } from "@screens/account/parts/learn-tracking-tab";
import { LearnStateRow } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-row/learn-state-row.component";
import { LearnStatesExportButtonWithPreloadedQuery } from "@screens/account/parts/learn-tracking-tab/parts/learn-states-export-button/learn-states-export-button.component";
import {
	CSV_QUERY,
	LEARN_STATES_FOR_USER_FRAGMENT,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-row/learn-tracking-row.graphql";
import {
	avatarColumnClass,
	coursesClass,
	coursesTextClass,
	donutChartClass,
	learnStatesHeaderClass,
	learnStatesListClass,
	learnStatesWrapperClass,
	learnTrackingClass,
	learnTrackingDataClass,
	learnTrackingRowClass,
	progressColumnClass,
	settingsColumnClass,
	userNameClass,
	userWrapperClass,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-row/learn-tracking-row.styles";
import {
	ExpandableNodePropsWithQuery,
	LearnTrackingRowProps,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-row/learn-tracking-row.types";
import { H3Span, H4Span, P3Span } from "@themes/font-tags";

export const LearnTrackingRow = ({ learnTrackingFragmentRef }: LearnTrackingRowProps) => {
	const { filters } = useContext(LearnTrackingTabContext);
	const [csvQueryRef, loadCsvQuery] = useQueryLoader<learnTrackingRow_Query>(CSV_QUERY);

	const optionsOverlayPanelRef = useRef<OverlayPanel>(null);

	const learnStatesForUser = useFragment<learnTrackingRow_LearnStatesForUserFragment$key>(
		LEARN_STATES_FOR_USER_FRAGMENT,
		learnTrackingFragmentRef ?? null,
	);

	const handleOptionsOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation();

		if (!csvQueryRef) {
			loadCsvQuery({
				filterByUserId: learnStatesForUser?.user.id,
				sortByUserLastName: filters.sortByUserLastName,
				filterByGroupIds: filters.filterByGroupIds,
				filterByText: filters.filterByText,
				filterByMinDate: filters.filterByMinDate,
				filterByMaxDate: filters.filterByMaxDate,
				filterByCompletedCourses: filters.filterByCompletedCourses,
			});
		}
		optionsOverlayPanelRef.current?.toggle(event);
	};

	const handleBoxToggle = () => {
		if (!csvQueryRef) {
			loadCsvQuery({
				filterByUserId: learnStatesForUser?.user.id,
				sortByUserLastName: filters.sortByUserLastName,
				filterByGroupIds: filters.filterByGroupIds,
				filterByText: filters.filterByText,
				filterByMinDate: filters.filterByMinDate,
				filterByMaxDate: filters.filterByMaxDate,
				filterByCompletedCourses: filters.filterByCompletedCourses,
			});
		}
	};

	const ExpandableNode = ({ isOpen, csvQueryRef }: ExpandableNodePropsWithQuery) => {
		return (
			<div className={learnTrackingRowClass}>
				<div className={avatarColumnClass}>
					<Avatar name={learnStatesForUser?.user.name} />
				</div>
				<div className={learnTrackingDataClass}>
					<div className={userWrapperClass}>
						<div className={userNameClass}>
							<P3Span>Teilnehmer*in</P3Span>
							<H3Span>{learnStatesForUser?.user.name}</H3Span>
						</div>
					</div>
					<div className={progressColumnClass}>
						<div className={coursesClass}>
							<P3Span className={coursesTextClass}>Kurse:</P3Span>
							<P3Span>{learnStatesForUser?.learnStates.length}</P3Span>
						</div>
						<div className={donutChartClass}>
							{learnStatesForUser?.averageProgressInPercent &&
							learnStatesForUser?.averageProgressInPercent >= 100 ? (
								<Icon icon="checkBadge" sizeInRem={1.5} />
							) : (
								<DonutProgressChart
									progressInPercent={
										learnStatesForUser?.averageProgressInPercent ?? 0
									}
								/>
							)}
							<P3Span>
								{Math.round(learnStatesForUser?.averageProgressInPercent ?? 0)}%
							</P3Span>
						</div>
					</div>
				</div>
				<div className={settingsColumnClass}>
					<OverlayPanel ref={optionsOverlayPanelRef}>
						{csvQueryRef ? (
							<LearnStatesExportButtonWithPreloadedQuery csvQueryRef={csvQueryRef} />
						) : (
							<EmptyPlaceholder subtitle="Optionen werden geladen..." />
						)}
					</OverlayPanel>
					<Icon icon="dots" sizeInRem={1} onClick={handleOptionsOnClick} />
					<Icon icon={isOpen ? "arrowUp" : "arrowDown"} sizeInRem={2} />
				</div>
			</div>
		);
	};

	return (
		<div className={learnTrackingClass}>
			<ExpandableBox
				key={learnStatesForUser?.user.id}
				isOpenable={!!learnStatesForUser?.learnStates?.length}
				expandableNode={(props) => <ExpandableNode {...props} csvQueryRef={csvQueryRef!} />}
				onToggle={handleBoxToggle}
			>
				<div className={learnStatesWrapperClass}>
					<div className={learnStatesHeaderClass}>
						<H4Span>Kursname</H4Span>
						<H4Span>Start</H4Span>
						<H4Span>Abgeschlossen</H4Span>
						<H4Span>Punkte</H4Span>
					</div>
					<EmptyPlaceholder
						isVisible={!learnStatesForUser?.learnStates?.length}
						subtitle="Momentan gibt es keine Lernfortschritte zur Ansicht"
					/>
					<div className={learnStatesListClass}>
						{learnStatesForUser?.learnStates.map((learnState) => (
							<LearnStateRow
								learnStateFragmentRef={learnState}
								key={learnState.title}
							/>
						))}
					</div>
				</div>
			</ExpandableBox>
		</div>
	);
};
