import { ButtonSkeleton } from "@components/button/button.skeleton";
import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";
import { learnTrackingListClass } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.styles";

import { headerClass } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-tab-header/learn-tracking-tab-header.styles";
import { SearchBarSkeleton } from "@screens/account/parts/search-bar/search-bar.skeleton";
import { H2Span } from "@themes/font-tags";

export const LearnTrackingTabSkeleton = () => {
	return (
		<div className={learnTrackingListClass}>
			<div className={headerClass}>
				<div>
					<H2Span>Nach Teilnehmer</H2Span>
				</div>
				<SearchBarSkeleton />
				<ButtonSkeleton />
			</div>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</div>
	);
};
