import { PathBase, PathWithId } from "@constellation-academy/epic-ui";

export class ProductsPath extends PathBase {
	static readonly pathKey = "products";
}

export class InvoicePath extends PathBase {
	static readonly pathKey = "invoice";
}

export class OverviewPath extends PathBase {
	static readonly pathKey = "overview";
}

export class SuccessPath extends PathBase {
	static readonly pathKey = "success";
}
export class PaymentMethodPath extends PathBase {
	static readonly pathKey = "paymentMethod";
	static readonly pathName = "payment-method";
}

export class ShoppingCartPath extends PathWithId<typeof ShoppingCartPath> {
	static readonly pathKey = "shoppingCart";
	static readonly pathName = "payment-cart";
	static readonly idKey = "shoppingCartId";
	static readonly childPaths = [
		ProductsPath,
		InvoicePath,
		PaymentMethodPath,
		OverviewPath,
		SuccessPath,
	];
}
