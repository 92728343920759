import graphql from "babel-plugin-relay/macro";

export const LEARN_STATES_FOR_USER_FRAGMENT = graphql`
	fragment learnTrackingRow_LearnStatesForUserFragment on LearnStatesForUser {
		user {
			id
			name
			extension(userExtensionKind: Academies) {
				... on AcademiesUserExtension {
					avatar {
						thumbnail
						url
						name
					}
				}
			}
		}
		averageProgressInPercent
		learnStates {
			title
			...learnStateRow_LearnStatesFragment
		}
	}
`;

export const CSV_QUERY = graphql`
	query learnTrackingRow_Query(
		$filterByUserId: ID
		$sortByUserLastName: SortOrder
		$filterByText: String
		$filterByMinDate: LocalDate
		$filterByMaxDate: LocalDate
		$filterByGroupIds: [ID!]
		$filterByCompletedCourses: Boolean
	) {
		...learnStatesExportButton_QueryFragment
			@arguments(
				filterByUserId: $filterByUserId
				sortByUserLastName: $sortByUserLastName
				filterByText: $filterByText
				filterByMinDate: $filterByMinDate
				filterByMaxDate: $filterByMaxDate
				filterByGroupIds: $filterByGroupIds
				filterByCompletedCourses: $filterByCompletedCourses
			)
	}
`;
