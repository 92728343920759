import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { Button } from "@components/button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { withSuspense } from "@components/with-suspense";
import { learnTrackingList_Query } from "@relay/learnTrackingList_Query.graphql";
import { learnTrackingList_QueryFragment$key } from "@relay/learnTrackingList_QueryFragment.graphql";
import { learnTrackingList_QueryFragmentRefetch } from "@relay/learnTrackingList_QueryFragmentRefetch.graphql";
import { LearnTrackingTabContext } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.context";
import {
	LEARN_TRACKING_LIST_QUERY_FRAGMENT,
	QUERY,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-list/learn-tracking-list.graphql";
import { LearnTrackingListSkeleton } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-list/learn-tracking-list.skeleton";
import { LearnTrackingRow } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-row/learn-tracking-row.component";

import { LearnTrackingListRef } from "./learn-tracking-list.types";

export const LearnTrackingListComponent = forwardRef<LearnTrackingListRef>(
	function LearnTrackingList({}, ref) {
		const [paginationCount, setPaginationCount] = useState(10);
		const { filters } = useContext(LearnTrackingTabContext);

		const query = useLazyLoadQuery<learnTrackingList_Query>(
			QUERY,
			{ filterByGroupIds: [] },
			{ fetchPolicy: "store-and-network" },
		);

		const { data, refetch, hasNext, loadNext } = usePaginationFragment<
			learnTrackingList_QueryFragmentRefetch,
			learnTrackingList_QueryFragment$key
		>(LEARN_TRACKING_LIST_QUERY_FRAGMENT, query);

		const learnTrackingConnection = data?.Viewer.Learntracking.learntracking;

		const learnTrackings = learnTrackingConnection?.edges?.map((edge) => edge?.node) ?? [];

		const filteredLearntrackings = learnTrackings.filter(
			(learnTracking) => learnTracking?.learnStates && learnTracking?.learnStates.length > 0,
		);

		const paginatedLearnTrackings = filteredLearntrackings.slice(0, paginationCount);

		const handleRefetch = () => {
			refetch(
				{
					...filters,
				},
				{ fetchPolicy: "store-and-network" },
			);
		};

		useEffect(() => {
			handleRefetch();
		}, [filters]);

		useImperativeHandle(ref, () => ({
			refetch: () => {
				handleRefetch();
			},
		}));

		const handleShowMore = () => {
			if (paginationCount >= paginatedLearnTrackings.length && hasNext) {
				loadNext(10, {
					onComplete: (error) => {
						if (!error) {
							setPaginationCount((prevCount) => prevCount + 10);
						}
					},
				});
			}
			setPaginationCount(paginationCount + 10);
		};

		return (
			<>
				<EmptyPlaceholder
					isVisible={paginatedLearnTrackings.length <= 0}
					subtitle="Momentan gibt es keine Lernfortschritte zur Ansicht"
				/>
				{paginatedLearnTrackings.map((learnTracking) => (
					<LearnTrackingRow
						learnTrackingFragmentRef={learnTracking}
						key={learnTracking?.user?.id}
					/>
				))}
				{paginationCount < paginatedLearnTrackings.length && (
					<Button
						onClick={handleShowMore}
						label="Mehr anzeigen"
						colorVersion="tertiary"
					/>
				)}
			</>
		);
	},
);

export const LearnTrackingList = withSuspense(
	LearnTrackingListComponent,
	LearnTrackingListSkeleton,
);
