import { grid } from "@styled-system/patterns";

export const learnTrackingRowClass = grid({
	width: "full",
	gridTemplateColumns: "[5% 1fr 15% 15% 15%]",
	alignItems: "center",
	gap: "12",
	p: "12",
	mdDown: {
		display: "none",
	},
});
