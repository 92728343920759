import { PathParams } from "@constellation-academy/epic-ui";
import { Suspense, useEffect, useMemo } from "react";
import { useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { Button } from "@components/button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { materialOverview_LearnOpportunityV2Fragment$key } from "@relay/materialOverview_LearnOpportunityV2Fragment.graphql";
import { materialOverview_Query } from "@relay/materialOverview_Query.graphql";
import { MaterialsPath, RootPath } from "@router/paths";

import { H1Span, TkaP2Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@utils/index-path.util";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	MATERIAL_OVERVIEW_QUERY,
} from "./material-overview.graphql";
import { MaterialOverviewSkeleton } from "./material-overview.skeleton";
import { PreviewWrapper, TitleWrapper, Wrapper } from "./material-overview.styles";
import {
	MaterialOverviewProps,
	MaterialOverviewWithPreloadedQueryProps,
} from "./material-overview.types";
import { MaterialsTab } from "../materials-tab";

export const MaterialOverviewWithPreloadedQuery = ({
	showDetails,
	queryRef,
}: MaterialOverviewWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<materialOverview_Query>(MATERIAL_OVERVIEW_QUERY, queryRef);

	const node = useFragment<materialOverview_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		query.node,
	);

	const { materialId } = useParams<PathParams<typeof MaterialsPath>>();

	const { t } = useTkaTranslation("modulesAndLessons");
	const { isMediumUp, isXLargeUp } = useWindowSize();

	const [content, attachment] = useMemo(() => {
		return (
			node?.typeDefinition.children?.flatMap((branch) =>
				branch.typeDefinition.children
					?.map((content) => {
						const attachment = content.attachmentsV2?.find(
							(attachment) => attachment.id === materialId,
						);
						if (attachment) {
							return [content, attachment] as [typeof content, typeof attachment];
						}
						return undefined;
					})
					.filter(Boolean)
					.map((result) => result!),
			)?.[0] ?? [undefined, undefined]
		);
	}, [materialId, node?.typeDefinition.children]);

	const branchNumber = content?.indexPath && getBranchNodeNumberFromIndexPath(content?.indexPath);
	const contentNumber =
		content?.indexPath && getContentNodeNumberFromIndexPath(content?.indexPath);

	const contentPath = `${t("modules_and_lessons.lesson")} ${branchNumber}.${contentNumber}`;

	const file = attachment?.file;

	const overview = attachment ? (
		<Wrapper>
			<TitleWrapper>
				<TkaP2Span>{contentPath}</TkaP2Span>
				<H1Span>{attachment?.title}</H1Span>
				{attachment?.description && <TkaP2Span>{attachment?.description}</TkaP2Span>}
			</TitleWrapper>
			<PreviewWrapper>
				<a
					href={file?.url ?? ""}
					target="_blank"
					rel="noopener noreferrer"
					download={file?.name}
				>
					<Button
						fillParent={!isMediumUp}
						label="Download"
						iconName="downloadBottom"
						minWidthRem={15}
					/>
				</a>
			</PreviewWrapper>
		</Wrapper>
	) : (
		<EmptyPlaceholder
			iconName="dataFileBarsDownload"
			title="Keine Vorschau verfügbar"
			subtitle="Wähle eine Lektion aus."
		/>
	);

	return isXLargeUp || showDetails ? (
		overview
	) : node ? (
		<MaterialsTab learnOpportunityV2FragmentRef={node} />
	) : null;
};

export const MaterialOverview = (props: MaterialOverviewProps) => {
	const { rootId } = useParams<PathParams<typeof RootPath>>();
	const [queryReference, loadQuery] =
		useQueryLoader<materialOverview_Query>(MATERIAL_OVERVIEW_QUERY);

	useEffect(() => {
		rootId && loadQuery({ id: rootId });
	}, [loadQuery, rootId]);

	return (
		<Suspense fallback={<MaterialOverviewSkeleton />}>
			{queryReference && (
				<MaterialOverviewWithPreloadedQuery {...props} queryRef={queryReference} />
			)}
		</Suspense>
	);
};
