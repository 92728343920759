/**
 * @generated SignedSource<<4bcd304aa935015ea2a4a82b9419b45f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortOrder = "ASC" | "DESC";
export type learnTrackingTabHeader_Query$variables = {
  filterByCompletedCourses?: boolean | null;
  filterByGroupIds?: ReadonlyArray<string> | null;
  filterByMaxDate?: string | null;
  filterByMinDate?: string | null;
  filterByText?: string | null;
  sortByUserLastName?: SortOrder | null;
};
export type learnTrackingTabHeader_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"learnStatesExportButton_QueryFragment">;
};
export type learnTrackingTabHeader_Query = {
  response: learnTrackingTabHeader_Query$data;
  variables: learnTrackingTabHeader_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByCompletedCourses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByGroupIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByMaxDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByMinDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByText"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortByUserLastName"
},
v6 = [
  {
    "kind": "Variable",
    "name": "filterByCompletedCourses",
    "variableName": "filterByCompletedCourses"
  },
  {
    "kind": "Variable",
    "name": "filterByGroupIds",
    "variableName": "filterByGroupIds"
  },
  {
    "kind": "Variable",
    "name": "filterByMaxDate",
    "variableName": "filterByMaxDate"
  },
  {
    "kind": "Variable",
    "name": "filterByMinDate",
    "variableName": "filterByMinDate"
  },
  {
    "kind": "Variable",
    "name": "filterByText",
    "variableName": "filterByText"
  },
  {
    "kind": "Variable",
    "name": "sortByUserLastName",
    "variableName": "sortByUserLastName"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "learnTrackingTabHeader_Query",
    "selections": [
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "learnStatesExportButton_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "learnTrackingTabHeader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearntrackingViewerSchema",
            "kind": "LinkedField",
            "name": "Learntracking",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "kind": "ScalarField",
                "name": "csv",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aba59d549917c00bff15564583800a49",
    "id": null,
    "metadata": {},
    "name": "learnTrackingTabHeader_Query",
    "operationKind": "query",
    "text": "query learnTrackingTabHeader_Query(\n  $sortByUserLastName: SortOrder\n  $filterByText: String\n  $filterByMinDate: LocalDate\n  $filterByMaxDate: LocalDate\n  $filterByGroupIds: [ID!]\n  $filterByCompletedCourses: Boolean\n) {\n  ...learnStatesExportButton_QueryFragment_Ce50w\n}\n\nfragment learnStatesExportButton_QueryFragment_Ce50w on Query {\n  Viewer {\n    Learntracking {\n      csv(sortByUserLastName: $sortByUserLastName, filterByText: $filterByText, filterByMinDate: $filterByMinDate, filterByMaxDate: $filterByMaxDate, filterByGroupIds: $filterByGroupIds, filterByCompletedCourses: $filterByCompletedCourses)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f84911c4a44f19cf471ed4691b1d087e";

export default node;
