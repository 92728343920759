import { TabPanel } from "primereact/tabview";
import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { TabBar } from "@components/tab-bar";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { LearnTrackingTabSkeleton } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.skeleton";
import { H1Span } from "@themes/font-tags";
import { Spacing12 } from "@utils/parse-html/parse-html.styles";
import { useTabIndexFromPath } from "./account.hooks";
import { Wrapper } from "./account.styles";
import { AccountTabSkeleton } from "./parts/account-tab";
import { GroupsTabSkeleton } from "./parts/groups-tab/groups-tab.skeleton";
import { MembersTabSkeleton } from "./parts/members-tab/members-tab.skeleton";

// TODO: add-translations
export const AccountScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();
	const tabIndex = useTabIndexFromPath();

	return (
		<ScreenWithProfileMenuLayout
			canGoBack={!isMediumUp}
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<Wrapper>
				<H1Span>Konto</H1Span>
				<TabBar disableMaxParentHeight activeIndex={tabIndex} onTabChange={() => {}}>
					<TabPanel header="Konto">
						<AccountTabSkeleton />
					</TabPanel>

					<TabPanel header="Mitglieder">
						<Spacing12 />
						<MembersTabSkeleton />
					</TabPanel>

					<TabPanel header="Gruppen">
						<Spacing12 />
						<GroupsTabSkeleton />
					</TabPanel>

					<TabPanel header="Lernfortschritt">
						<Spacing12 />
						<LearnTrackingTabSkeleton />
					</TabPanel>
				</TabBar>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
