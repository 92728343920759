import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment learnStatesExportButton_QueryFragment on Query
	@argumentDefinitions(
		filterByUserId: { type: "ID", defaultValue: null }
		sortByUserLastName: { type: "SortOrder", defaultValue: ASC }
		filterByText: { type: "String", defaultValue: null }
		filterByMinDate: { type: "LocalDate", defaultValue: null }
		filterByMaxDate: { type: "LocalDate", defaultValue: null }
		filterByGroupIds: { type: "[ID!]", defaultValue: null }
		filterByCompletedCourses: { type: "Boolean", defaultValue: false }
	) {
		Viewer {
			Learntracking {
				csv(
					filterByUserId: $filterByUserId
					sortByUserLastName: $sortByUserLastName
					filterByText: $filterByText
					filterByMinDate: $filterByMinDate
					filterByMaxDate: $filterByMaxDate
					filterByGroupIds: $filterByGroupIds
					filterByCompletedCourses: $filterByCompletedCourses
				)
			}
		}
	}
`;
