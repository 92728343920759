/**
 * @generated SignedSource<<401a4edb7866b0e633ea10d1966b9c81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type learnStateRow_LearnStatesFragment$data = {
  readonly progressInPercent: number | null;
  readonly scores: {
    readonly performanceInPercent: number;
    readonly receivedPoints: number;
    readonly totalPoints: number;
  };
  readonly startedAt: string | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"learnStateProgressBar_LearnStatesFragment">;
  readonly " $fragmentType": "learnStateRow_LearnStatesFragment";
};
export type learnStateRow_LearnStatesFragment$key = {
  readonly " $data"?: learnStateRow_LearnStatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnStateRow_LearnStatesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "learnStateRow_LearnStatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressInPercent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PerformanceScores",
      "kind": "LinkedField",
      "name": "scores",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performanceInPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPoints",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "receivedPoints",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "learnStateProgressBar_LearnStatesFragment"
    }
  ],
  "type": "LearnStates",
  "abstractKey": null
};

(node as any).hash = "0cc6ecdc4a4b2db1598629f4a07b9252";

export default node;
