/**
 * @generated SignedSource<<1e778961b5221f9fddcc765464ef199f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type learnStatesExportButton_QueryFragment$data = {
  readonly Viewer: {
    readonly Learntracking: {
      readonly csv: string;
    };
  };
  readonly " $fragmentType": "learnStatesExportButton_QueryFragment";
};
export type learnStatesExportButton_QueryFragment$key = {
  readonly " $data"?: learnStatesExportButton_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnStatesExportButton_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "filterByCompletedCourses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByMaxDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByMinDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUserId"
    },
    {
      "defaultValue": "ASC",
      "kind": "LocalArgument",
      "name": "sortByUserLastName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "learnStatesExportButton_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearntrackingViewerSchema",
          "kind": "LinkedField",
          "name": "Learntracking",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByCompletedCourses",
                  "variableName": "filterByCompletedCourses"
                },
                {
                  "kind": "Variable",
                  "name": "filterByGroupIds",
                  "variableName": "filterByGroupIds"
                },
                {
                  "kind": "Variable",
                  "name": "filterByMaxDate",
                  "variableName": "filterByMaxDate"
                },
                {
                  "kind": "Variable",
                  "name": "filterByMinDate",
                  "variableName": "filterByMinDate"
                },
                {
                  "kind": "Variable",
                  "name": "filterByText",
                  "variableName": "filterByText"
                },
                {
                  "kind": "Variable",
                  "name": "filterByUserId",
                  "variableName": "filterByUserId"
                },
                {
                  "kind": "Variable",
                  "name": "sortByUserLastName",
                  "variableName": "sortByUserLastName"
                }
              ],
              "kind": "ScalarField",
              "name": "csv",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "29f5bab6fcd1cbe66bc589f623603214";

export default node;
