import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const iconClass = flex({ gap: "4" });
export const searchBarClass = css({
	overflow: "hidden",
	flex: "[1 1 auto]",
	minWidth: "0",
	whiteSpace: "nowrap",
});

export const getWrapperAnimation = (showSearch: boolean) => ({
	animate: { justifyContent: showSearch ? "flex-start" : "center" },
	transition: { type: "spring", stiffness: 300, damping: 20 },
});

export const inputAnimation = {
	initial: { opacity: 0, width: 0 },
	animate: { opacity: 1, width: "auto" },
	exit: { opacity: 0, width: 0 },
	transition: { duration: 0.3 },
};
