import { PathParams } from "@constellation-academy/epic-ui";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { InputText } from "@components/input-text";
import { textQuestion_PotentialAnalysisQuestionFragment$key } from "@relay/textQuestion_PotentialAnalysisQuestionFragment.graphql";
import { textQuestion_SetAnswerInTextQuestionMutation } from "@relay/textQuestion_SetAnswerInTextQuestionMutation.graphql";
import { AvgsPotentialAnalysisPath } from "@router/paths";
import {
	POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
	SET_ANSWER_IN_TEXT_QUESTION_MUTATION,
} from "./text-question.graphql";
import { wrapperClass } from "./text-question.styles";
import { TextQuestionProps } from "./text-question.types";
import { isQuestionAnswered } from "../avgs-potential-analysis-navigation/avgs-potential-analysis-navigation.utils";

export const TextQuestion = ({
	isActive,
	potentialAnalysisQuestionFragmentRef,
}: TextQuestionProps) => {
	const { potentialAnalysisId } = useParams<PathParams<typeof AvgsPotentialAnalysisPath>>();

	const question = useFragment<textQuestion_PotentialAnalysisQuestionFragment$key>(
		POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
		potentialAnalysisQuestionFragmentRef,
	);

	const [setAnswerInTextQuestion] = useMutation<textQuestion_SetAnswerInTextQuestionMutation>(
		SET_ANSWER_IN_TEXT_QUESTION_MUTATION,
	);

	const { questionText, id, givenAnswer } = question;

	const [text, setText] = useState<string>(givenAnswer?.text ?? "");

	const handleUpdateAnswer = (text: string) => {
		if (!id || !questionText || !potentialAnalysisId) return;
		setAnswerInTextQuestion({
			variables: {
				input: {
					questionId: id,
					questionText,
					learnPotentialAnalysisId: potentialAnalysisId,
					givenAnswer: {
						text,
					},
				},
			},
		});
	};

	const debounceHandleUpdateAnswer = useMemo(
		() =>
			_.debounce((text: string) => {
				handleUpdateAnswer(text);
			}, 500),
		[],
	);

	const handleOnChange = (text?: string) => {
		setText(text ?? "");
		debounceHandleUpdateAnswer(text ?? "");
	};

	const isAnswered = isQuestionAnswered(question);
	const isBlured = !isAnswered && !isActive;

	return (
		<div className={wrapperClass({ isBlured })}>
			<InputText
				textArea
				rows={3}
				value={text}
				onChange={handleOnChange}
				placeholder={"Deine Antwort..."}
			/>
		</div>
	);
};
