import { css, cva } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const learnStateRowClass = grid({
	width: "full",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	alignItems: "center",
	gap: "12",
	p: "12",
	md: {
		gridTemplateColumns: "[1fr 20% 15% 10%]",
	},
	mdDown: {
		gridTemplateColumns: "[auto auto]",
		gridTemplateRows: "[auto 1px auto]",
	},
});

export const donutChartClass = flex({
	gap: "8",
	alignItems: "center",
	mdDown: {
		order: "2",
		justifyContent: "end",
	},
});

export const progressBarClass = css({
	mdDown: {
		order: "1",
	},
	md: { pr: "96" },
});

export const dateClass = css({
	mdDown: {
		order: "4",
	},
});

export const tooltipClass = stack({
	px: "16",
	py: "8",
	backgroundColor: "shade.100",
	borderRadius: "4",
	margin: "[-0.5rem]",
	gap: "0",
});

export const pointsClass = cva({
	base: {
		mdDown: {
			display: "flex",
			justifyContent: "end",
			order: "4",
		},
	},
	variants: {
		color: {
			red: { color: "error.100" },
			yellow: { color: "warning.100" },
			blue: { color: "info.100" },
			green: { color: "success.100" },
		},
	},
});
export const borderClass = css({
	gridColumn: "[1 / -1]",
	gridRow: "2",
	height: "[1px]",
	backgroundColor: "shade.20",
	width: "full",
	order: "3",
	md: {
		display: "none",
	},
});
