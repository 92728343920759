import { ReactNode } from "react";
import { useFragment } from "react-relay";

import { match } from "ts-pattern";
import { StyledTooltip } from "@components/styled-tooltip/styled-tooltip.component";
import { TooltipContent } from "@components/styled-tooltip/styled-tooltip.types";
import { learnStateProgressBar_LearnStatesFragment$key } from "@relay/learnStateProgressBar_LearnStatesFragment.graphql";
import {
	MODULE_PROGRESS,
	MODULE_PROGRESS_TRANSLATIONS,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.consts";
import { LEARN_STATES_FRAGMENT } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.graphql";
import {
	contentProgressElementClass,
	contentProgressElementWrapperClass,
	contentProgressWrapperClass,
	moduleStateProgressTooltipClass,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.styles";
import {
	LearnStateProgressBarProps,
	ModuleProgress,
	ModuleState,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.types";
import { generateRandomizedIdPostfix } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.utils";

export const LearnStateProgressBar = ({ learnStateBranchesRef }: LearnStateProgressBarProps) => {
	const learnStateProgress = useFragment<learnStateProgressBar_LearnStatesFragment$key>(
		LEARN_STATES_FRAGMENT,
		learnStateBranchesRef,
	);

	const moduleStates = learnStateProgress.moduleStates ?? [];

	const renderTooltip = (moduleState: ModuleState): TooltipContent => {
		const moduleProgress = getModuleProgressState(moduleState);

		return {
			title: `${moduleState.title}${
				moduleState.lastEditedTitle
					? ` - ${moduleState.lastEditedTitle}`
					: " (nicht gestartet)"
			}`,
			content: (
				<>
					<div>
						{`${moduleState.scores.receivedPoints} von ${moduleState.scores.totalPoints} Punkten`}
					</div>
					<div
						className={moduleStateProgressTooltipClass({
							moduleProgress: moduleProgress,
						})}
					>
						{MODULE_PROGRESS_TRANSLATIONS[moduleProgress]}
					</div>
				</>
			),
		};
	};

	const getModuleProgressState = (moduleState: ModuleState): ModuleProgress => {
		const { scores } = moduleState;
		const progress = Math.round(scores.performanceInPercent);

		return match(progress)
			.when(
				(progress) => progress >= 100,
				() => MODULE_PROGRESS.COMPLETED,
			)
			.when(
				(progress) => progress > 0 && progress < 100,
				() => MODULE_PROGRESS.IN_PROGRESS,
			)
			.when(
				(progress) => progress <= 0,
				() => MODULE_PROGRESS.NOT_STARTED,
			)
			.otherwise(() => MODULE_PROGRESS.NOT_STARTED);
	};

	const renderProgressBarElement = (moduleState: ModuleState): ReactNode => {
		const idPostfix = generateRandomizedIdPostfix(moduleState.title);

		return (
			<div key={moduleState.title + idPostfix} className={contentProgressElementWrapperClass}>
				<div
					id={`learn-state-progress-bar-element-wrapper-${idPostfix}`}
					className={contentProgressElementClass({
						moduleProgress: getModuleProgressState(moduleState),
					})}
				/>
				<StyledTooltip
					tooltipContent={renderTooltip(moduleState)}
					target={`#learn-state-progress-bar-element-wrapper-${idPostfix}`}
				/>
			</div>
		);
	};

	return (
		<>
			<div className={contentProgressWrapperClass}>
				{moduleStates.map((moduleState) => renderProgressBarElement(moduleState))}
			</div>
		</>
	);
};
