/**
 * @generated SignedSource<<ca1c158c320e960c75f27c832dc8d129>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type learnStateProgressBar_LearnStatesFragment$data = {
  readonly moduleStates: ReadonlyArray<{
    readonly lastEditedAt: string | null;
    readonly lastEditedTitle: string | null;
    readonly scores: {
      readonly performanceInPercent: number;
      readonly receivedPoints: number;
      readonly totalPoints: number;
    };
    readonly title: string;
  }> | null;
  readonly " $fragmentType": "learnStateProgressBar_LearnStatesFragment";
};
export type learnStateProgressBar_LearnStatesFragment$key = {
  readonly " $data"?: learnStateProgressBar_LearnStatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnStateProgressBar_LearnStatesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "learnStateProgressBar_LearnStatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleStates",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastEditedTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastEditedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PerformanceScores",
          "kind": "LinkedField",
          "name": "scores",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "performanceInPercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivedPoints",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPoints",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnStates",
  "abstractKey": null
};

(node as any).hash = "97815c7b6f63bcacfbd8607a211a65e7";

export default node;
