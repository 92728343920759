import graphql from "babel-plugin-relay/macro";

export const CSV_QUERY = graphql`
	query learnTrackingTabHeader_Query(
		$sortByUserLastName: SortOrder
		$filterByText: String
		$filterByMinDate: LocalDate
		$filterByMaxDate: LocalDate
		$filterByGroupIds: [ID!]
		$filterByCompletedCourses: Boolean
	) {
		...learnStatesExportButton_QueryFragment
			@arguments(
				sortByUserLastName: $sortByUserLastName
				filterByText: $filterByText
				filterByMinDate: $filterByMinDate
				filterByMaxDate: $filterByMaxDate
				filterByGroupIds: $filterByGroupIds
				filterByCompletedCourses: $filterByCompletedCourses
			)
	}
`;
