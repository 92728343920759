export const generateRandomizedIdPostfix = (title: string): string => {
	const sanitizedTitle = title
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^a-z0-9-_]/g, "");

	const randomFactor = Math.random().toString(36).substring(2, 6);

	return `${sanitizedTitle}-${randomFactor}`;
};
