import { css } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const learnTrackingClass = css({
	width: "full",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	mdDown: {
		py: "12",
	},
});
export const learnStatesHeaderClass = grid({
	width: "full",
	alignItems: "center",
	gap: "12",
	px: "12",
	gridTemplateColumns: "[1fr 20% 15% 10%]",
	mdDown: {
		display: "none",
	},
});
export const learnStatesListClass = stack({
	gap: "4",
});
export const learnStatesWrapperClass = stack({
	md: {
		borderTop: "1px solid",
		borderColor: "shade.10",
	},
	py: "12",
	mx: "12",
	pl: "40",
});

export const learnTrackingRowClass = grid({
	md: {
		gridTemplateColumns: "[auto 1fr 15% 15% 15%]",
		width: "full",
		alignItems: "center",
		gap: "12",
		p: "12",
	},
	mdDown: {
		gridTemplateColumns: "[auto 1fr auto]",
		gap: "12",
		px: "12",
	},
});

export const learnTrackingDataClass = css({
	mdDown: { display: "block" },
	md: { display: "contents" },
});
export const progressColumnClass = flex({
	alignItems: "center",
	gap: "8",
	mdDown: { mt: "12" },
	md: { display: "contents" },
});

export const avatarColumnClass = flex({
	mdDown: { display: "flex", justifyContent: "center", alignItems: "center" },
});

export const userWrapperClass = flex({
	gap: "12",
	mdDown: { md: { display: "grid" } },
});

export const coursesClass = css({
	md: { display: "grid" },
});

export const donutChartClass = flex({
	gap: "8",
	alignItems: "center",
});

export const settingsColumnClass = flex({
	mdDown: {
		alignItems: "center",
	},
	gap: "24",
	justifyContent: "center",
});

export const coursesTextClass = css({
	md: { display: "none" },
	mr: "4",
});

export const userNameClass = stack({ gap: "0" });
