import { P2Span } from "@constellation-academy/epic-ui";
import { brandMain100 } from "@themes/colors";
import { wrapperClass } from "./license-required-card.styles";
import { ReactComponent as LoginKeyIcon } from "../../assets/icons/login-key.svg";

// TODO: add-translations
export const LicenseRequiredCard = () => {
	return (
		<div className={wrapperClass}>
			<LoginKeyIcon />
			<P2Span tkaColor={brandMain100}>
				Um diesen Kurs abschließen zu können, wird eine Lizenz benötigt.
			</P2Span>
		</div>
	);
};
