import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";

export const LearnTrackingListSkeleton = () => {
	return (
		<>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</>
	);
};
