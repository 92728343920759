import { learnTrackingRowClass } from "@screens/account/parts/learn-tracking-tab/parts/learn-trackings-list-header/learn-trackings-list-header.styles";
import { H4Span } from "@themes/font-tags";

export const LearnTrackingsListHeader = () => {
	return (
		<div className={learnTrackingRowClass}>
			<H4Span>Name</H4Span>
			<div />
			<H4Span>Kurse</H4Span>
			<H4Span>Abgeschlossen</H4Span>
			<div />
		</div>
	);
};
