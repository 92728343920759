import { useMemo, useReducer, useRef } from "react";
import { initialFilterState } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.consts";
import { LearnTrackingTabContext } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.context";
import { learnTrackingFiltersReducer } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.reducer";
import { learnTrackingListClass } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.styles";
import { LearnTrackingList } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-list/learn-tracking-list.component";
import { LearnTrackingTabHeader } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-tab-header/learn-tracking-tab-header.component";
import { LearnTrackingsListHeader } from "@screens/account/parts/learn-tracking-tab/parts/learn-trackings-list-header/learn-trackings-list-header.component";
import { LearnTrackingListRef } from "./learn-tracking-tab.types";
import { RefetchContext } from "../refetch-context/refetch.context";

export const LearnTrackingTab = () => {
	const [filters, dispatch] = useReducer(learnTrackingFiltersReducer, initialFilterState);

	const activeFiltersCount = useMemo(() => {
		return (
			(filters.filterByText ? 1 : 0) +
			(filters.filterByMinDate ? 1 : 0) +
			(filters.filterByMaxDate ? 1 : 0) +
			(filters.filterByGroupIds?.length ? 1 : 0) +
			(filters.filterByCompletedCourses ? 1 : 0)
		);
	}, [filters]);

	const contextValue = useMemo(
		() => ({
			activeFiltersCount,
			filters,
			dispatch,
		}),
		[activeFiltersCount, filters, dispatch],
	);

	const ref = useRef<LearnTrackingListRef>(null);

	const handleRefetch = () => {
		ref.current?.refetch();
	};

	return (
		<RefetchContext.Provider value={{ refetch: handleRefetch }}>
			<LearnTrackingTabContext.Provider value={contextValue}>
				<LearnTrackingTabHeader />
				<div className={learnTrackingListClass}>
					<LearnTrackingsListHeader />
					<LearnTrackingList ref={ref} />
				</div>
			</LearnTrackingTabContext.Provider>
		</RefetchContext.Provider>
	);
};
