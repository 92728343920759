import { H3Span, L1Span, P2Span, P3Span } from "@constellation-academy/epic-ui";
import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { Card } from "@components/card";
import { CircularProgressbar } from "@components/circular-progressbar";
import { Icon } from "@components/icon";
import { ImageCardAtom } from "@components/ImageCardAtom";
import { Label } from "@components/label";
import { PRODUCT_FRAGMENT, QUERY } from "@components/offer-card/offer-card.graphql";
import { offerCard_ProductFragment$key } from "@relay/offerCard_ProductFragment.graphql";
import { offerCard_Query } from "@relay/offerCard_Query.graphql";
import { ResidenceDropdownOptions } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";
import { css } from "@styled-system/css";
import { brandMain80, error100, shade0, shade20, warning100 } from "@themes/colors";
import { spacing0 } from "@themes/spacing";
import { formatCurrency } from "@utils/currency";
import { formatToTimeAgo } from "@utils/date-utils";
import { getGrossPriceByCountryCode } from "@utils/prices";
import {
	BackgroundClass,
	CardWrapperClass,
	CertificateAndPriceWrapperClass,
	CertificateAndTimeWrapperClass,
	CertificateWrapperClass,
	DescriptionWrapperClass,
	DetailPriceWrapperClass,
	DiplomaWrapperClass,
	ErrorTextClass,
	FooterClass,
	HeaderClass,
	InfoTextClass,
	JustifyBetweenClass,
	LicensesTextWrapperClass,
	PriceWrapperClass,
	TagsWrapperClass,
	TimeWrapperClass,
	titleClass,
	TopRightWrapperClass,
	WrapperClass,
} from "./offer-card.styles";
import { OfferCardProps } from "./offer-card.types";

export const OfferCard = ({
	productFragmentRef,
	title,
	shortDescription,
	progress,
	isLocked,
	showLicenseNeeded,
	availableLicenseCount = 0,
	src,
	tags,
	diploma,
	onClick,
	showPrice,
	lastUpdated,
}: OfferCardProps) => {
	const formatProgress = (progress: number): string => progress + "%";
	const progressPercentageTextComponent = progress ? (
		<P3Span className={css({ color: "shade.0" })}>{formatProgress(progress)}</P3Span>
	) : (
		<React.Fragment />
	);

	const product = useFragment<offerCard_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef ?? null,
	);

	const user = useLazyLoadQuery<offerCard_Query>(QUERY, {});
	const userCountryCode: ResidenceDropdownOptions =
		user.AccountBaseData.AccountBaseData.countryCode;
	const grossPrice = getGrossPriceByCountryCode(userCountryCode, [
		...(product?.extensions ?? []),
	]);

	const progressBarOrLockedComponent = isLocked ? (
		<Icon
			icon={"lock"}
			circleOutlineColor={shade0.withAlpha(0.4)}
			tkaColor={shade20}
			sizeInRem={1}
		/>
	) : progress ? (
		<CircularProgressbar value={progress} />
	) : (
		<React.Fragment />
	);

	const hasLicenses = availableLicenseCount > 0;
	const licensesText =
		availableLicenseCount > 0
			? `${availableLicenseCount}x Lizenz${availableLicenseCount > 1 ? "en" : ""} im Besitz`
			: "";
	const showAvailableLicensesPart =
		hasLicenses && !showLicenseNeeded ? (
			<React.Fragment>
				<P3Span className={InfoTextClass}>{licensesText}</P3Span>
				<Icon sizeInRem={1} icon={"checkBadge"} tkaColor={warning100} />
			</React.Fragment>
		) : (
			<React.Fragment />
		);
	const showLicenseNeededText = showLicenseNeeded ? "Lizenz benötigt" : "";
	const showLicenseNeededPart = showLicenseNeeded ? (
		<React.Fragment>
			<P3Span className={ErrorTextClass}>{showLicenseNeededText}</P3Span>
			<Icon tkaColor={error100} icon={"warning-inverted"} sizeInRem={1} />
		</React.Fragment>
	) : (
		<React.Fragment />
	);

	const taxWording = userCountryCode === "AT" ? "USt." : "MwSt.";

	const displayPrice = showPrice && grossPrice != undefined && grossPrice > 0 && (
		<div className={PriceWrapperClass}>
			<div className={DetailPriceWrapperClass}>
				<H3Span className={css({ color: "primary.100" })}>
					{formatCurrency(product?.netPrice)}
				</H3Span>
			</div>
			<div className={DetailPriceWrapperClass}>
				<L1Span className={css({ color: "primary.40" })}>
					{product?.isTaxFree
						? `${taxWording} befreit`
						: formatCurrency(grossPrice) + ` inkl. ${taxWording}`}
				</L1Span>
			</div>
		</div>
	);

	return (
		<div className={CardWrapperClass}>
			<Card fillHeight bodyPaddingInRem={0} onClick={onClick}>
				<div className={WrapperClass}>
					<div className={HeaderClass}>
						{src && (
							<ImageCardAtom
								imageUrl={src}
								isAbsolute
								imageWidth={"100%"}
								borderRadiusRem={spacing0}
							/>
						)}
						{hasLicenses || showLicenseNeeded || progress ? (
							<div className={BackgroundClass} />
						) : null}
						<div className={JustifyBetweenClass}>
							<div className={LicensesTextWrapperClass}>
								{showAvailableLicensesPart}
								{showLicenseNeededPart}
							</div>

							<div className={TopRightWrapperClass}>
								{progressPercentageTextComponent}
								{progressBarOrLockedComponent}
							</div>
						</div>
						<div className={JustifyBetweenClass}>
							{tags && (
								<div className={TagsWrapperClass}>
									{tags.slice(0, 3).map((t, i) => (
										<Label
											key={`tag-${t}-${i}-${Math.floor(
												Math.random() * 1000,
											)}`}
											label={t}
											severity={"brandStrong"}
										/>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={FooterClass}>
						<H3Span className={titleClass}>{title}</H3Span>
						<div className={DescriptionWrapperClass}>
							<P2Span className={css({ color: "primary.80" })}>
								{shortDescription}
							</P2Span>
						</div>
						<div className={CertificateAndPriceWrapperClass}>
							{diploma ? (
								<>
									<div className={CertificateAndTimeWrapperClass}>
										<div className={CertificateWrapperClass}>
											<div className={DiplomaWrapperClass}>
												<Icon
													icon="certifiedCertificateBold"
													tkaColor={brandMain80}
													sizeInRem={1}
												/>

												<P2Span className={css({ color: "primary.80" })}>
													{diploma}
												</P2Span>
											</div>
											{displayPrice}
										</div>
										{lastUpdated && (
											<div className={TimeWrapperClass}>
												<P3Span className={css({ color: "shade.40" })}>
													{formatToTimeAgo(lastUpdated)}
												</P3Span>
											</div>
										)}
									</div>
								</>
							) : (
								<>
									<div className={CertificateWrapperClass} />
									{displayPrice}
									{lastUpdated && (
										<div className={TimeWrapperClass}>
											<P3Span className={css({ color: "shade.40" })}>
												{formatToTimeAgo(lastUpdated)}
											</P3Span>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
};
