import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Permission } from "@relay/permissionBasedNavigation_Query.graphql";
import { selectJwtClaimData } from "@slices/auth/auth.selectors";

export const usePermissions = () => {
	const jwtClaimData = useSelector(selectJwtClaimData);
	const checkPermissions = (necessaryPermissions: Permission[]) => {
		const permissions = jwtClaimData?.permissionsInAccount ?? [];
		return necessaryPermissions.every((permission) => permissions.includes(permission));
	};

	const isPlacementContactPerson = useMemo(
		() => checkPermissions(["UserInAccountPermission_PlacementContactPerson_Access"]),
		[jwtClaimData?.permissionsInAccount],
	);

	const isCoachAccount = useMemo(
		() => checkPermissions(["AccountPermission_CoachingCoachProfileAdmin_IsCoachAccount"]),
		[jwtClaimData?.permissionsInAccount],
	);
	const isBusinessAccount = useMemo(
		() => checkPermissions(["AccountPermission_Auth_IsBusinessAccount"]),
		[jwtClaimData?.permissionsInAccount],
	);

	const isAccountOwner = useMemo(
		() => checkPermissions(["UserInAccountPermission_System_Owner"]),
		[jwtClaimData?.permissionsInAccount],
	);

	const canInviteUsersViaCSVUpload = useMemo(
		() =>
			checkPermissions([
				"AccountPermission_AccountMemberManagement_CanInviteOrAddUsersToUserInAccountGroups",
			]),
		[jwtClaimData?.permissionsInAccount],
	);

	const hasPlacementPermission = useMemo(
		() => checkPermissions(["AccountPermission_Auth_usePlacement"]),
		[jwtClaimData?.permissionsInAccount],
	);

	const isAZAVAccount = useMemo(
		() => checkPermissions(["AccountPermission_AZAVAdmin_IsAZAVAccount"]),
		[jwtClaimData?.permissionsInAccount],
	);

	const isAVGSAdmin = useMemo(
		() => checkPermissions(["UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSAdmin"]),
		[jwtClaimData?.permissionsInAccount],
	);

	return {
		isCoachAccount,
		isBusinessAccount,
		isAccountOwner,
		canInviteUsersViaCSVUpload,
		hasPlacementPermission,
		isPlacementContactPerson,
		isAZAVAccount,
		isAVGSAdmin,
	};
};
