import { FC, useContext } from "react";
import { Button } from "@components/button";

import { Label } from "@components/label";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { SearchBar } from "@screens/account/parts/search-bar";
import { SearchBarMobile } from "@screens/account/parts/search-bar/search-bar-mobile";
import { TkaH2Span } from "@themes/font-tags";
import {
	ButtonWrapper,
	HeaderWrapper,
	LabelsWrapper,
	MembersAndLabelsWrapper,
	SearchAndFiltersWrapper,
	SearchBarMobileWrapper,
} from "./header.styles";
import { HeaderProps } from "./header.types";
import { MembersTabContext } from "../../members-tab.context";
import { MembersFilter } from "../members-filter-component/members-filter.component";

export const Header: FC<HeaderProps> = ({
	searchIsVisibleOnMobile,
	handleToggleOnClick,
	handleSearchOnChange,
	handleInviteOnClick,
	searchByNameOrEmail,
	userInAccountGroupQueryFragment,
}) => {
	const { numberOfConfirmedMembers, numberOfUnconfirmedMembers } = useContext(MembersTabContext);
	const { isAccountOwner, isAVGSAdmin } = usePermissions();
	const { isMediumUp } = useWindowSize();
	return (
		<HeaderWrapper>
			<MembersAndLabelsWrapper>
				<TkaH2Span>Mitglieder</TkaH2Span>
				<LabelsWrapper>
					<Label label={`${numberOfConfirmedMembers} Zugesagt`} />
					<Label label={`${numberOfUnconfirmedMembers} unbestätigt`} />
				</LabelsWrapper>
			</MembersAndLabelsWrapper>
			<SearchAndFiltersWrapper>
				<SearchBar
					onClick={handleToggleOnClick}
					onChange={handleSearchOnChange}
					value={searchByNameOrEmail}
				/>
				<MembersFilter queryFragmentRef={userInAccountGroupQueryFragment} />
			</SearchAndFiltersWrapper>
			{searchIsVisibleOnMobile && (
				<SearchBarMobileWrapper>
					<SearchBarMobile onChange={handleSearchOnChange} value={searchByNameOrEmail} />
				</SearchBarMobileWrapper>
			)}
			{(isAccountOwner || isAVGSAdmin) && (
				<ButtonWrapper>
					<Button
						colorVersion="outline"
						label="Hinzufügen"
						iconName="add"
						onClick={handleInviteOnClick}
						fillParent={!isMediumUp}
					/>
				</ButtonWrapper>
			)}
		</HeaderWrapper>
	);
};
