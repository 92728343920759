import { flex } from "@styled-system/patterns";

export const headerClass = flex({
	mdDown: {
		"& > :last-child": {
			flexBasis: "full",
		},
		mb: "16",
	},
	"& > :first-child": {
		marginRight: "auto",
	},
	wrap: "wrap",
	gap: "16",
	justifyContent: "space-between",
	alignItems: "center",
	flexWrap: "wrap",
	mb: "16",
});

export const filterClass = flex({
	position: "relative",
	alignItems: "center",
	cursor: "pointer",
});
export const filterCountClass = flex({
	position: "absolute",
	top: "[-50%]",
	right: "[-50%]",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "secondary.100",
	borderRadius: "full",
	fontSize: "10",
	minWidth: "[12]",
	aspectRatio: "1 / 1",
	color: "shade.0",
});
