export function stripHtmlPreserveLineBreaks(html: string) {
	html = html
		.replace(/<\/p>/gi, "\n\u00A0")
		.replace(/<\/div>/gi, "\n\u00A0")
		.replace(/<br\s*\/?>/gi, "BR_LINE_BREAK"); // temporärer Marker

	const doc = new DOMParser().parseFromString(html, "text/html");
	let text = doc.body.textContent || "";
	text = text.replace(/BR_LINE_BREAK/g, "\n");

	return text;
}
