import { MODULE_PROGRESS } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.consts";
import { cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const contentProgressWrapperClass = flex({
	gap: "4",
	flexWrap: "wrap",
	width: "full",
	justifyContent: "space-between",
});

export const contentProgressElementWrapperClass = flex({
	flex: "1",
});

export const contentProgressElementClass = cva({
	base: {
		borderRadius: "full",
		height: "8",
		minWidth: "4",
		flex: "1",
	},
	variants: {
		moduleProgress: {
			completed: { backgroundColor: "secondary.100" },
			inProgress: {
				backgroundColor: "warning.100",
			},
			notStarted: { backgroundColor: "shade.10" },
		},
	},
});

export const moduleStateProgressTooltipClass = cva({
	variants: {
		moduleProgress: {
			[MODULE_PROGRESS.COMPLETED]: { color: "secondary.100" },
			[MODULE_PROGRESS.IN_PROGRESS]: { color: "warning.100" },
			[MODULE_PROGRESS.NOT_STARTED]: { color: "primary.40" },
		},
	},
});
