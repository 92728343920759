import lodash from "lodash";
import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { useState, useRef, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Outlet } from "react-router-dom";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { TabBar } from "@components/tab-bar";
import { withSuspense } from "@components/with-suspense";
import { useCurrentPath } from "@hooks/use-current-path";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { account_Query } from "@relay/account_Query.graphql";
import { Path } from "@router/paths";
import { LearnTrackingTab } from "@screens/account/parts/learn-tracking-tab";
import { Spacing24 } from "@screens/profile/profile.styles";
import { H1Span } from "@themes/font-tags";
import { AccountContext } from "./account.context";
import { QUERY } from "./account.graphql";
import { useNavigateToTab, useTabIndexFromPath } from "./account.hooks";
import { AccountScreenSkeleton } from "./account.skeleton";
import { Wrapper } from "./account.styles";
import { OutletContext } from "./account.types";
import { AccountTab } from "./parts/account-tab";
import { AccountTabRef } from "./parts/account-tab/account-tab.types";
import { MembersTab } from "./parts/members-tab";
import { FilterContextProvider } from "./parts/members-tab/parts/members-filter-component/members-filter.context";

// TODO: add-translations
export const AccountScreenComponent = () => {
	const query = useLazyLoadQuery<account_Query>(QUERY, {}, { fetchPolicy: "store-and-network" });

	const accountTabRef = useRef<AccountTabRef>(null);
	const currentPath = useCurrentPath();
	const { isAccountOwner, isAVGSAdmin } = usePermissions();
	const { isMediumUp } = useWindowSize();
	const tabIndex = useTabIndexFromPath();
	const navigate = useNavigateToTab();

	const [membersConnectionIds, setMembersConnectionIds] = useState<string[]>([]);
	const [groupsConnectionIds, setGroupsConnectionIds] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const isBasePath = currentPath?.route.path === Path.profileMenu.account.path;
	useEffect(() => {
		if (isBasePath) {
			navigate(0);
		}
	}, [isBasePath]);

	const handleAddGroupConnectionId = (id: string) => {
		setGroupsConnectionIds(lodash.uniq([...groupsConnectionIds, id]));
	};

	const handleAddMembersConnectionId = (id: string) => {
		setMembersConnectionIds(lodash.uniq([...membersConnectionIds, id]));
	};

	const handleTabOnChange = (event: TabViewTabChangeEvent) => {
		navigate(event.index);
	};

	const handleSaveOnClick = () => {
		accountTabRef.current?.save();
	};

	const isAccountTabOpen = tabIndex === 0;

	const outletContextValue: OutletContext = {
		groupsTabProps: {
			queryFragmentRef: query,
			userInAccountGroupQueryFragmentRef: query,
		},
	};
	return (
		<AccountContext.Provider
			value={{
				membersConnectionIds,
				addMembersConnectionId: handleAddMembersConnectionId,
				groupsConnectionIds,
				addGroupsConnectionId: handleAddGroupConnectionId,
			}}
		>
			<FilterContextProvider>
				<ScreenWithProfileMenuLayout
					canGoBack={!isMediumUp}
					bottomContent={
						isAccountOwner &&
						isAccountTabOpen && (
							<BottomNavigationTemplate
								actionButtonLabel={"Speichern"}
								onActionButtonClick={handleSaveOnClick}
								actionButtonLoading={isLoading}
							/>
						)
					}
				>
					<Wrapper>
						<H1Span>Konto</H1Span>
						<TabBar
							disableMaxParentHeight
							activeIndex={tabIndex}
							onTabChange={handleTabOnChange}
						>
							<TabPanel header="Konto">
								<AccountTab
									ref={accountTabRef}
									queryFragmentRef={query}
									onLoadingStateChanged={setIsLoading}
								/>
							</TabPanel>
							<TabPanel
								header="Mitglieder"
								disabled={!isAccountOwner && !isAVGSAdmin}
							>
								<Spacing24 />
								<MembersTab
									accountMembersQueryFragmentRef={query}
									userInAccountGroupQueryFragmentRef={query}
								/>
							</TabPanel>
							<TabPanel header="Gruppen" disabled={!isAccountOwner && !isAVGSAdmin}>
								<Spacing24 />
								<Outlet context={outletContextValue} />
							</TabPanel>
							<TabPanel header="Lernfortschritt" disabled={!isAccountOwner}>
								<Spacing24 />
								<LearnTrackingTab />
							</TabPanel>
						</TabBar>
					</Wrapper>
				</ScreenWithProfileMenuLayout>
			</FilterContextProvider>
		</AccountContext.Provider>
	);
};

export const AccountScreen = withSuspense(AccountScreenComponent, AccountScreenSkeleton);
