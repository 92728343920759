import { PathParams } from "@constellation-academy/epic-ui";
import { useFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { BranchNodesTree } from "@components/branch-nodes-tree";
import { CertificateCard } from "@components/certificate-card";
import { BranchNodesPath } from "@router/paths";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./branch-nodes-tab.graphql";
import { TabWrapper } from "./branch-nodes-tab.styles";
import { BranchNodeTabProps } from "./branch-nodes-tab.types";
import { useRootNavigation } from "../root.util";

export const BranchNodeTab = ({ learnOpportunityV2FragmentRef }: BranchNodeTabProps) => {
	const { branchNodeId } = useParams<PathParams<typeof BranchNodesPath>>();
	const { handleContentNodeOnClick } = useRootNavigation();

	const root = useFragment(LEARN_OPPORTUNITY_V2_FRAGMENT, learnOpportunityV2FragmentRef);

	const rootName = root.structureDefinition.title;
	const isIHK = root.structureDefinition.extension?.reducedData?.isIHK;
	const canOrderIhk = root.structureDefinition.extension?.ihkState === "ReadyToOrder";

	return (
		<TabWrapper>
			{root && (
				<BranchNodesTree
					learnOpportunityV2FragmentRef={root}
					onContentNodeClick={handleContentNodeOnClick}
					activeNodeId={branchNodeId}
				/>
			)}
			{isIHK && rootName && (
				<CertificateCard rootName={rootName} canOrder={canOrderIhk} rootId={root.id} />
			)}
		</TabWrapper>
	);
};
