import { css, cva } from "@styled-system/css";

export const headerClass = cva({
	variants: {
		isOpenable: {
			true: {
				cursor: "pointer",
			},
			false: {
				cursor: "default",
			},
		},
	},
});

export const contentClass = css({
	overflow: "hidden",
});
