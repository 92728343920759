import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { DonutProgressChart } from "@components/donut-progress-chart";
import { Icon } from "@components/icon";
import { StyledTooltip } from "@components/styled-tooltip/styled-tooltip.component";
import { TooltipContent } from "@components/styled-tooltip/styled-tooltip.types";

import { learnStateRow_LearnStatesFragment$key } from "@relay/learnStateRow_LearnStatesFragment.graphql";
import { LearnStateProgressBar } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.component";
import { LEARN_STATES_FRAGMENT } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-row/learn-state-row.graphql";
import {
	borderClass,
	dateClass,
	donutChartClass,
	learnStateRowClass,
	pointsClass,
	progressBarClass,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-state-row/learn-state-row.styles";
import {
	LearnStateRowProps,
	PointsColor,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-state-row/learn-state-row.types";
import { P3Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";

export const LearnStateRow = ({ learnStateFragmentRef }: LearnStateRowProps) => {
	const learnState = useFragment<learnStateRow_LearnStatesFragment$key>(
		LEARN_STATES_FRAGMENT,
		learnStateFragmentRef,
	);

	const pointsColor: PointsColor = match(learnState.scores.performanceInPercent)
		.returnType<PointsColor>()
		.when(
			(progressValue: number) => progressValue >= 0 && progressValue <= 25,
			() => "red",
		)
		.when(
			(progressValue: number) => progressValue > 25 && progressValue <= 40,
			() => "yellow",
		)
		.when(
			(progressValue: number) => progressValue > 40 && progressValue <= 75,
			() => "blue",
		)
		.when(
			(progressValue: number) => progressValue > 75 && progressValue <= 100,
			() => "green",
		)
		.otherwise(() => "red");

	const donutProgressChartTooltip: TooltipContent = {
		title:
			learnState.progressInPercent && learnState.progressInPercent >= 100
				? "Abgeschlossen"
				: "In Bearbeitung",
		content: learnState.startedAt ? formatDateTime(learnState.startedAt) : "",
	};

	return (
		<>
			<StyledTooltip
				tooltipContent={donutProgressChartTooltip}
				target="#learn-state-row-donut-progress-chart-wrapper"
			/>
			<div className={learnStateRowClass}>
				<div className={progressBarClass}>
					<P3Span>{learnState.title}</P3Span>
					<LearnStateProgressBar learnStateBranchesRef={learnState} />
				</div>
				<P3Span className={dateClass}>
					{learnState.startedAt ? formatDateTime(learnState.startedAt) : ""}
				</P3Span>
				<div className={borderClass} />
				<div id="learn-state-row-donut-progress-chart-wrapper" className={donutChartClass}>
					{learnState.progressInPercent && learnState.progressInPercent >= 100 ? (
						<Icon icon="checkBadge" sizeInRem={1.5} />
					) : (
						<DonutProgressChart progressInPercent={learnState.progressInPercent ?? 0} />
					)}
					<P3Span>{Math.round(learnState.progressInPercent ?? 0)}%</P3Span>
				</div>
				<P3Span className={pointsClass({ color: pointsColor })}>
					{`${learnState.scores.receivedPoints}/${learnState.scores.totalPoints}`}
				</P3Span>
			</div>
		</>
	);
};
