import { PathParams } from "@constellation-academy/epic-ui";
import { useEffect } from "react";
import { useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/Loader";
import { createOrder_CreateOrderMutation } from "@relay/createOrder_CreateOrderMutation.graphql";
import { CreateOrderPath, Path } from "@router/paths";
import { CREATE_ORDER_MUTATION } from "./create-order.graphql";
import { Wrapper } from "./create-order.styles";
import { Cart } from "./create-order.types";
import { gtmTrackInitiateCheckout } from "../../analytics/google-tag-manager";

export const CreateOrderScreen = () => {
	const [createOrder] = useMutation<createOrder_CreateOrderMutation>(CREATE_ORDER_MUTATION);

	const navigate = useNavigate();
	const { createOrderJson } = useParams<PathParams<typeof CreateOrderPath>>();

	useEffect(() => {
		if (!createOrderJson) {
			return navigate(Path.progression.path);
		}
		try {
			const cart: Cart = JSON.parse(atob(createOrderJson));
			const cartSelection = {
				selectedDiscountCodes: cart.selectedDiscountCodes,
				selectedProducts: cart.selectedProduct,
			};

			createOrder({
				variables: {
					input: {
						cartSelection,
					},
				},
				onCompleted: (response) => {
					const orderId = response.Billing.createOrder?.order.id;
					if (!orderId) {
						return navigate(Path.progression.path);
					}
					gtmTrackInitiateCheckout(
						cart.selectedProduct.map((product) => {
							return {
								base64EncodedProductId: product.productId,
								quantity: product.amount!,
								productName:
									response.Billing.createOrder?.order?.cart?.items.find(
										(p) => p.product?.id === product.productId,
									)?.product?.title ?? "",
							};
						}),
						orderId,
						response.Billing.createOrder?.order?.cart?.totals?.includingAllDiscounts
							.netPrice ?? 0,
					);
					navigate(Path.shoppingCart.withId(orderId).products.path, { replace: true });
				},
			});
		} catch (_) {
			return navigate(Path.progression.path);
		}
	}, []);
	return (
		<Wrapper>
			<Loader />
		</Wrapper>
	);
};
