import {
	donutInnerClass,
	donutWrapperClass,
} from "@components/donut-progress-chart/donut-progress-chart.styles";
import {
	DonutProgressChartProps,
	LearnProgressRunnerStyle,
} from "@components/donut-progress-chart/donut-progress-chart.types";

export const DonutProgressChart = ({ progressInPercent }: DonutProgressChartProps) => {
	const rotation = (360 * progressInPercent) / 100;

	return (
		<div
			className={donutWrapperClass}
			style={
				{
					"--progress": `${rotation}deg`,
				} as LearnProgressRunnerStyle
			}
		>
			<div className={donutInnerClass}></div>
		</div>
	);
};
