/**
 * @generated SignedSource<<d4ce85b88574aa2701f7f17ad0b979af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type learnTrackingList_QueryFragment$data = {
  readonly Viewer: {
    readonly Learntracking: {
      readonly learntracking: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly learnStates: ReadonlyArray<{
              readonly title: string;
            }>;
            readonly user: {
              readonly id: string;
            };
            readonly " $fragmentSpreads": FragmentRefs<"learnTrackingRow_LearnStatesForUserFragment">;
          };
        } | null> | null;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
        };
      };
    };
  };
  readonly " $fragmentType": "learnTrackingList_QueryFragment";
};
export type learnTrackingList_QueryFragment$key = {
  readonly " $data"?: learnTrackingList_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnTrackingList_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Viewer",
  "Learntracking",
  "learntracking"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "filterByCompletedCourses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByMaxDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByMinDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": "ASC",
      "kind": "LocalArgument",
      "name": "sortByUserLastName"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./learnTrackingList_QueryFragmentRefetch.graphql')
    }
  },
  "name": "learnTrackingList_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearntrackingViewerSchema",
          "kind": "LinkedField",
          "name": "Learntracking",
          "plural": false,
          "selections": [
            {
              "alias": "learntracking",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByCompletedCourses",
                  "variableName": "filterByCompletedCourses"
                },
                {
                  "kind": "Variable",
                  "name": "filterByGroupIds",
                  "variableName": "filterByGroupIds"
                },
                {
                  "kind": "Variable",
                  "name": "filterByMaxDate",
                  "variableName": "filterByMaxDate"
                },
                {
                  "kind": "Variable",
                  "name": "filterByMinDate",
                  "variableName": "filterByMinDate"
                },
                {
                  "kind": "Variable",
                  "name": "filterByText",
                  "variableName": "filterByText"
                },
                {
                  "kind": "Variable",
                  "name": "sortByUserLastName",
                  "variableName": "sortByUserLastName"
                }
              ],
              "concreteType": "LearnStatesForUserConnection",
              "kind": "LinkedField",
              "name": "__learnTrackingList_learntracking_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnStatesForUserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LearnStatesForUser",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "learnTrackingRow_LearnStatesForUserFragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnStates",
                          "kind": "LinkedField",
                          "name": "learnStates",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0621a87f003a49e33cf2f8d883e398c9";

export default node;
