import { MouseEventHandler, useContext, useState } from "react";
import { useQueryLoader } from "react-relay";
import { Button } from "@components/button";
import { ExtendableSearchbar } from "@components/extendable-searchbar/extendable-searchbar.component";
import { Icon } from "@components/icon";
import { learnTrackingTabHeader_Query } from "@relay/learnTrackingTabHeader_Query.graphql";
import { LearnTrackingTabContext } from "@screens/account/parts/learn-tracking-tab";
import { LearnStatesExportButtonWithPreloadedQuery } from "@screens/account/parts/learn-tracking-tab/parts/learn-states-export-button/learn-states-export-button.component";
import { LearnTrackingFilters } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-filters/learn-tracking-filters.component";

import { CSV_QUERY } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-tab-header/learn-tracking-tab-header.graphql";
import {
	filterClass,
	filterCountClass,
	headerClass,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-tab-header/learn-tracking-tab-header.styles";
import { H2Span } from "@themes/font-tags";
import useDebounce from "@utils/useDebounce";

export const LearnTrackingTabHeader = () => {
	const { filters, dispatch, activeFiltersCount } = useContext(LearnTrackingTabContext);
	const [areFiltersVisible, setAreFiltersVisible] = useState(false);
	const [csvQueryRef, loadCsvQuery] = useQueryLoader<learnTrackingTabHeader_Query>(CSV_QUERY);

	const handleFiltersOnClick = () => {
		setAreFiltersVisible(!areFiltersVisible);
	};
	const handleFiltersOnHide = () => {
		setAreFiltersVisible(false);
	};
	const handleSearchOnChange = (searchBy: string) => {
		dispatch({
			type: "setFilterByText",
			value: searchBy,
		});
	};

	const debouncedSearchValue = useDebounce(filters.filterByText, 500);

	const handleExportClick: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.stopPropagation();
		if (!csvQueryRef) {
			loadCsvQuery({
				sortByUserLastName: filters.sortByUserLastName,
				filterByGroupIds: filters.filterByGroupIds,
				filterByText: debouncedSearchValue,
				filterByMinDate: filters.filterByMinDate,
				filterByMaxDate: filters.filterByMaxDate,
				filterByCompletedCourses: filters.filterByCompletedCourses,
			});
		}
	};

	return (
		<div className={headerClass}>
			<div>
				<H2Span>Nach Teilnehmer</H2Span>
			</div>
			<ExtendableSearchbar value={filters.filterByText} onChange={handleSearchOnChange} />
			<div className={filterClass} onClick={handleFiltersOnClick}>
				<Icon icon="filter" sizeInRem={1} />
				{activeFiltersCount > 0 && (
					<div className={filterCountClass}>{activeFiltersCount}</div>
				)}
			</div>
			<div>
				{csvQueryRef ? (
					<LearnStatesExportButtonWithPreloadedQuery csvQueryRef={csvQueryRef} />
				) : (
					<Button
						colorVersion="outline"
						label="Export"
						icon={<Icon icon="excel" sizeInRem={1} />}
						onClick={handleExportClick}
					/>
				)}
			</div>
			{areFiltersVisible && (
				<LearnTrackingFilters onHide={handleFiltersOnHide} isVisible={areFiltersVisible} />
			)}
		</div>
	);
};
