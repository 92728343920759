import styled from "styled-components";
import { BreakpointInPx } from "@themes/breakpoint";
import { spacing12 } from "@themes/spacing";

export const VideoWrapper = styled.div`
	border-radius: 0.625rem;
	overflow: hidden;
	margin-top: ${spacing12.rem()};

	@media screen and (min-width: ${BreakpointInPx.large}px) {
		height: 250px;
	}
	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		height: 435px;
	}
`;
