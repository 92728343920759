import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectUserInAccountGroupsField_Query($filterByAccountId: ID!) {
		Auth {
			SearchUserInAccountGroupsAsAccountOwnerQuery(filterByAccountId: $filterByAccountId) {
				id
				name
			}
		}
	}
`;
