import { Navigate } from "react-router-dom";
import { RouteDefinition } from "@navigation/route-definition.types";
import { AcceptInvitationScreen } from "@screens/accept-invitation";
import { AcceptInvitationErrorScreen } from "@screens/accept-invitation-error";
import { AccountScreen } from "@screens/account";
import { GroupDetails } from "@screens/account/parts/group-details";
import { GroupsTab } from "@screens/account/parts/groups-tab";
import { LearnTrackingTab } from "@screens/account/parts/learn-tracking-tab";
import { AccountSelectionScreen } from "@screens/account-selection/account-selection.screen";
import { ActivateScreen } from "@screens/activate";
import { ActivateErrorScreen } from "@screens/activate-error";
import { ActivateSuccessScreen } from "@screens/activate-success";
import { AppointmentsScreen } from "@screens/appointments";
import { AvgsPotentialAnalysisScreen } from "@screens/avgs-potential-analysis";
import { BookAppointmentScreen } from "@screens/book-appointment";
import { CoachScreen } from "@screens/coach";
import { CoachingDetailsScreen } from "@screens/coaching-details";
import { ContentSubmission } from "@screens/content-submission/ContentSubmission.screen";
import { CreateOrderScreen } from "@screens/create-order";
import { DashboardScreen } from "@screens/dashboard";
import { ForgotPasswordScreen } from "@screens/forgot-password";
import { ImprintScreen } from "@screens/imprint";
import { LicensesScreen } from "@screens/licenses";
import { LicenseDetails } from "@screens/licenses/parts/license-details";
import { LoginScreen } from "@screens/login";
import { MyAvailabilitiesScreen } from "@screens/my-availabilities";
import { MyCoachingOffersScreen } from "@screens/my-coaching-offers";
import { MyDocumentsScreen } from "@screens/my-documents";
import { OffersScreen } from "@screens/offers";
import { OrientationScreen } from "@screens/orientation";
import { PlacementScreen } from "@screens/placement";
import { ProfileScreen } from "@screens/profile";
import { ProfileMenuScreen } from "@screens/profile-menu";
import { ResetPasswordScreen } from "@screens/reset-password";
import { RootScreen } from "@screens/root";
import { ContentOverview } from "@screens/root/content-overview";
import { ContentOverviewMobile } from "@screens/root/content-overview-mobile";
import { MaterialOverview } from "@screens/root/material-overview";
import { RootOverview } from "@screens/root/root-overview";
import { ShoppingCartInvoiceScreen } from "@screens/shopping-cart-invoice";
import { ShoppingCartOverviewScreenWithPaymentContext } from "@screens/shopping-cart-overview/shopping-cart-overview.screen";
import { ShoppingCartProductsScreen } from "@screens/shopping-cart-products";
import { ShoppingCartSuccessScreen } from "@screens/shopping-cart-success";
import { SignUpScreen } from "@screens/sign-up";
import { TrendsScreen } from "@screens/trends";
import TrendsDetailScreen from "@screens/trends-detail/trends-detail.screen";
import { VocabularyScreen } from "@screens/vocabulary";
import { BranchNodesPath, MaterialsPath, Path, RootOverviewPath } from "./paths";
import { AccountDataPath, GroupsPath, LearnTrackingPath, MembersPath } from "./paths/profile";

export const Routes: RouteDefinition[] = [
	{
		requiredPermissions: "only-logged-out",
		path: Path.login.path,
		element: <LoginScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.forgotPassword.path,
		element: <ForgotPasswordScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.resetPassword.withIdPlaceholder().path,
		element: <ResetPasswordScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.signup.path,
		element: <SignUpScreen />,
	},
	{
		requiredPermissions: "first-login",
		path: Path.login.path,
		element: <Navigate replace to={Path.orientation.path} />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.signup.path,
		element: <Navigate replace to={Path.orientation.path} />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.acceptInvitation.withIdPlaceholder().path,
		element: <AcceptInvitationScreen />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.acceptInvitation.withIdPlaceholder().error.path,
		element: <AcceptInvitationErrorScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.activate.withIdPlaceholder().success.path,
		element: <ActivateSuccessScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.activate.withIdPlaceholder().error.path,
		element: <ActivateErrorScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: Path.activate.withIdPlaceholder().path,
		element: <ActivateScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.progression.path,
		element: <OffersScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.coachProfile.withIdPlaceholder().path,
		element: <CoachScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.trends.path,
		element: <TrendsScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.trends.detail.withIdPlaceholder().path,
		element: <TrendsDetailScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.root.withIdPlaceholder().path,
		element: <RootScreen />,
		children: [
			{
				path: RootOverviewPath.pathName,
				element: <RootOverview />,
			},
			{
				path: BranchNodesPath.pathName,
				element: <ContentOverview />,
			},
			{
				path: BranchNodesPath.withIdPlaceholder().path,
				element: <ContentOverview />,
			},
			{
				path: MaterialsPath.pathName,
				element: <MaterialOverview />,
			},
			{
				path: MaterialsPath.withIdPlaceholder().path,
				element: <MaterialOverview />,
			},
		],
	},
	{
		requiredPermissions: "logged-in",
		path: Path.root.withIdPlaceholder().branchNodes.withIdPlaceholder().lessonOverview.path,
		element: <ContentOverviewMobile />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.contentSubmission.withIdPlaceholder().path,
		element: <ContentSubmission />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.contentSubmission.withIdPlaceholder().branchNodes.path,
		element: <ContentSubmission />,
	},

	{
		requiredPermissions: "logged-in",
		path: "/",
		element: <Navigate replace to={Path.progression.path} />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.createOrder.withIdPlaceholder().path,
		element: <CreateOrderScreen />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.shoppingCart.withIdPlaceholder().products.path,
		element: <ShoppingCartProductsScreen />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.shoppingCart.withIdPlaceholder().invoice.path,
		element: <ShoppingCartInvoiceScreen />,
	},
	{
		requiredPermissions: "logged-in-and-logged-out",
		path: Path.shoppingCart.withIdPlaceholder().overview.path,
		element: <ShoppingCartOverviewScreenWithPaymentContext />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.shoppingCart.withIdPlaceholder().success.path,
		element: <ShoppingCartSuccessScreen />,
	},

	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.path,
		element: <ProfileMenuScreen />,
	},

	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.profile.path,
		element: <ProfileScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.appointments.path,
		element: <AppointmentsScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.licenses.path,
		element: <LicensesScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.licenses.detail.withIdPlaceholder().path,
		element: <LicenseDetails />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.licenses.detail.withIdPlaceholder().createSingle.path,
		element: <LicenseDetails />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.licenses.detail.withIdPlaceholder().createGroup.path,
		element: <LicenseDetails />,
	},

	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.vocabulary.path,
		element: <VocabularyScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.documents.path,
		element: <MyDocumentsScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.myAvailabilities.path,
		element: <MyAvailabilitiesScreen />,
	},

	{
		requiredPermissions: "logged-in",
		path: Path.coaching.withIdPlaceholder().path,
		element: <CoachingDetailsScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.bookAppointment.withIdPlaceholder().path,
		element: <BookAppointmentScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.orientation.path,
		element: <OrientationScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.placement.path,
		element: <PlacementScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.myCoachingOffers.path,
		element: <MyCoachingOffersScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.dashboard.path,
		element: <DashboardScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.avgsPotentialAnalysis.withIdPlaceholder().path,
		element: <AvgsPotentialAnalysisScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.selectAccount.path,
		element: <AccountSelectionScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.account.path,
		element: <AccountScreen />,
		children: [
			{
				path: AccountDataPath.pathName,
				element: <GroupsTab />,
			},
			{
				path: MembersPath.pathName,
				element: <GroupsTab />,
			},
			{
				path: LearnTrackingPath.pathName,
				element: <LearnTrackingTab />,
			},
			{
				path: GroupsPath.pathName,
				element: <GroupsTab />,
			},
			{
				path: GroupsPath.withIdPlaceholder().path,
				element: <GroupDetails />,
			},
		],
	},
	{
		requiredPermissions: "logged-in",
		path: Path.profileMenu.imprint.path,
		element: <ImprintScreen />,
	},
];
