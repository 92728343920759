import { useFragment, usePreloadedQuery } from "react-relay";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { useToast } from "@hooks/useToast";
import { learnStatesExportButton_QueryFragment$key } from "@relay/learnStatesExportButton_QueryFragment.graphql";
import { QUERY_FRAGMENT } from "@screens/account/parts/learn-tracking-tab/parts/learn-states-export-button/learn-states-export-button.graphql";
import {
	LearnStatesExportButtonProps,
	LearnStatesExportButtonWithPreloadedQueryProps,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-states-export-button/learn-states-export-button.types";
import { CSV_QUERY } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-row/learn-tracking-row.graphql";

export const LearnStatesExportButton = ({ csvQueryFragmentRef }: LearnStatesExportButtonProps) => {
	const { showError } = useToast();

	const csvResult = useFragment<learnStatesExportButton_QueryFragment$key>(
		QUERY_FRAGMENT,
		csvQueryFragmentRef,
	);

	const downloadCsv = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (csvResult.Viewer.Learntracking.csv) {
			const link = document.createElement("a");
			link.href = "data:text/csv;charset=utf-8," + csvResult.Viewer.Learntracking.csv;
			link.download = "Learntracking.csv";
			link.click();
		} else {
			showError({
				summary: "Fehler beim Download",
				detail: "Der Download war leider nicht erfolgreich.",
			});
		}
	};

	return (
		<Button
			onClick={downloadCsv}
			fillParent
			colorVersion="outline"
			label="Export"
			icon={<Icon icon="excel" sizeInRem={1} />}
		/>
	);
};

export const LearnStatesExportButtonWithPreloadedQuery = ({
	csvQueryRef,
}: LearnStatesExportButtonWithPreloadedQueryProps) => {
	const csvQueryData = usePreloadedQuery(CSV_QUERY, csvQueryRef);
	return <LearnStatesExportButton csvQueryFragmentRef={csvQueryData} />;
};
