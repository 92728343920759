import { FC } from "react";
import { spacing16 } from "@components/cloze-text-element/cloze-text-element.styles";
import { TkaH3Span, TkaP3Span } from "@themes/font-tags";

import { CardHeader, CardWrapper, NameWrapper } from "./feedback-card.styles";

import { FeedbackCardProps } from "./feedback-card.types";
import { Avatar } from "../avatar";

export const FeedbackCardComponent: FC<FeedbackCardProps> = ({ name, position, feedback }) => {
	return (
		<CardWrapper>
			<CardHeader>
				<Avatar firstName={name} />
				<NameWrapper>
					<TkaH3Span>{name}</TkaH3Span>
					<TkaP3Span italic>{position}</TkaP3Span>
				</NameWrapper>
			</CardHeader>
			<div className={spacing16} />
			<TkaP3Span italic>{feedback}</TkaP3Span>
		</CardWrapper>
	);
};
