import { PathParams } from "@constellation-academy/epic-ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "@hooks/use-auth-context";
import { ActivatePath, Path } from "@router/index";

export const ActivateScreen = () => {
	const { token } = useParams<PathParams<typeof ActivatePath>>();
	const navigate = useNavigate();
	const { activateUser } = useAuthContext();

	useEffect(() => {
		if (!token) {
			navigate(Path.activate.withIdPlaceholder().error.path);
			return;
		}
		activateUser(token)
			.then(() => {
				navigate(Path.activate.withId(token).success.path);
			})
			.catch(() => {
				navigate(Path.activate.withId(token).error.path);
			});
	}, []);

	return null;
};
