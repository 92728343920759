import { PathParams } from "@constellation-academy/epic-ui";
import { TabPanel, type TabViewTabChangeEvent } from "primereact/tabview";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { Icon } from "@components/icon";
import { Message } from "@components/message";
import { Spacing16 } from "@components/news-card/news-card.styles";
import { TabBar } from "@components/tab-bar";
import { useCurrentPath } from "@hooks/use-current-path";
import { useWindowSize } from "@hooks/use-window-size";
import { useToast } from "@hooks/useToast";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";
import { type licenseDetails_LicenseGroupingFragment$key } from "@relay/licenseDetails_LicenseGroupingFragment.graphql";
import { type licenseDetails_Query } from "@relay/licenseDetails_Query.graphql";
import { type licenseDetails_RefetchQuery } from "@relay/licenseDetails_RefetchQuery.graphql";
import { Path } from "@router/paths";
import { LicenseDetailsPath } from "@router/paths/profile";
import { LicensesNewRuleModal } from "@screens/licenses/parts/license-details/parts/licenses-new-rule-modal";
import { OffersLocationState } from "@screens/offers/offers.types";
import { Spacing24 } from "@screens/profile/profile.styles";
import { selectCurrentAccountId } from "@slices/auth/auth.selectors";
import { shade60, shade80, warning100 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaH3Span, TkaP1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { AccountData, getLicenseModelKindForAccount } from "@utils/licenses-utils";
import { parseHtml } from "@utils/parse-html";
import { Spacing32 } from "@utils/parse-html/parse-html.styles";
import { QUERY_FRAGMENT, QUERY } from "./license-details.graphql";
import {
	Breadcrumbs,
	LegendLicenseBar,
	LicenseBarContainer,
	LicenseInfoWrapper,
	LicenseLabel,
	LicenseLabels,
	StyledButton,
	UsedLicensesBar,
	Wrapper,
} from "./license-details.styles";
import { GroupRulesTab } from "./parts/group-rules-tab";
import { UserRulesTab } from "./parts/user-rules-tab";
import { LicenseProductCard } from "../license-product-card/license-product-card.component";
import { LicenseSideMenu } from "../licenses-side-menu/licenses-side-menu.component";

enum TabNames {
	Groups = 0,
	Users = 1,
}

export const LicenseDetails = () => {
	const { licenseId } = useParams<PathParams<typeof LicenseDetailsPath>>();
	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();
	const { showSuccess } = useToast();
	const currentPath = useCurrentPath();
	const [activeIndex, setActiveIndex] = useState(() =>
		currentPath?.pathname.includes("create")
			? currentPath?.pathname.includes("create-group")
				? TabNames.Groups
				: TabNames.Users
			: TabNames.Groups,
	);
	const [showNewRuleModal, setShowNewRuleModal] = useState(
		() => currentPath?.pathname.includes("create-") ?? false,
	);

	const query = useLazyLoadQuery<licenseDetails_Query>(QUERY, {
		licenseGroupingId: licenseId!,
	});
	const [data, refetch] = useRefetchableFragment<
		licenseDetails_RefetchQuery,
		licenseDetails_LicenseGroupingFragment$key
	>(QUERY_FRAGMENT, query);

	const currentAccountId: string | undefined = useSelector(selectCurrentAccountId);
	const accountData = query?.Viewer?.Auth?.currentUser?.accounts;
	const licenseModel =
		(accountData &&
			getLicenseModelKindForAccount(accountData as AccountData, currentAccountId)) ??
		"FreeForAll";

	const groupRules =
		data.LicenseGrouping?.LicenseGrouping.assignmentRules.filter(
			(rule) => rule.__typename === "AccountGroupAssignmentRule",
		) ?? [];
	const userRules =
		data.LicenseGrouping?.LicenseGrouping?.assignmentRules.filter(
			(rule) => rule.__typename !== "AccountGroupAssignmentRule",
		) ?? [];

	const availableGroups =
		query?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers.edges
			?.map((edge) => edge?.node.group!)
			.filter(Boolean) ?? [];

	const licenseDescription = parseHtml(
		data.LicenseGrouping.LicenseGrouping.learnable?.groupCoaching?.description?.description ??
			data.LicenseGrouping.LicenseGrouping.learnable?.oneToOneCoaching?.description
				?.description ??
			data.LicenseGrouping.LicenseGrouping.learnable?.root?.description ??
			"",
	);
	const availableLicenses = data.LicenseGrouping?.LicenseGrouping?.totalLicenseAmount ?? 0;
	const usedLicenses = data.LicenseGrouping?.LicenseGrouping?.usedLicenseAmount ?? 0;
	const remainingLicenses = availableLicenses - usedLicenses;
	const usedLicensesPercentage = (usedLicenses / availableLicenses) * 100;

	const handleGoToLearnable = () => {
		if (
			(data.LicenseGrouping.LicenseGrouping.learnable?.kind === "GroupCoaching" ||
				data.LicenseGrouping.LicenseGrouping.learnable?.kind === "OneToOneCoaching") &&
			(data.LicenseGrouping.LicenseGrouping.learnable?.groupCoaching?.id ??
				data.LicenseGrouping.LicenseGrouping.learnable?.oneToOneCoaching?.id)
		) {
			navigate(
				Path.coaching.withId(
					data.LicenseGrouping.LicenseGrouping.learnable?.groupCoaching?.id ??
						data.LicenseGrouping.LicenseGrouping.learnable?.oneToOneCoaching?.id ??
						"",
				).path,
			);
		}
		if (
			data.LicenseGrouping.LicenseGrouping.learnable?.kind === "Course" &&
			data.LicenseGrouping.LicenseGrouping.learnable?.root?.id
		) {
			navigate(
				Path.root.withId(data.LicenseGrouping.LicenseGrouping.learnable?.root?.id).path,
			);
		}
		if (data.LicenseGrouping.LicenseGrouping.kind === "MicroLearning") {
			const state: Partial<OffersLocationState> = {
				state: { filters: { kinds: ["MicroLearning"] } },
			};
			navigate(Path.progression.path, state);
		}
	};

	const handleOnTabChange = (e: TabViewTabChangeEvent) => {
		setActiveIndex(e.index);
	};

	const handleGoToLicenses = () => {
		navigate(Path.profileMenu.licenses.path, { state: { activeIndex: 1 } });
	};

	const handleOnCreateRule = () => {
		showSuccess({
			summary: "Die Zuweisung wurde erstellt",
		});
		if (currentPath?.pathname.includes("create-")) {
			navigate(Path.profileMenu.licenses.detail.withId(licenseId!).path);
		}

		refetch(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const handleChangeSettingsOnClick = () => {
		navigate(Path.profileMenu.licenses.path, { state: { activeIndex: 1, showSettings: true } });
	};

	return (
		<>
			{showNewRuleModal && (
				<LicensesNewRuleModal
					isVisible
					defaultActiveIndex={activeIndex}
					fragmentRef={query}
					groupOptions={availableGroups}
					licenseGroupingId={licenseId!}
					onDismiss={() => setShowNewRuleModal(false)}
					onCreateRule={handleOnCreateRule}
				/>
			)}
			<ScreenWithNavbarSidemenuLayout
				sideMenu={
					<LicenseSideMenu queryFragmentRef={data.LicenseGrouping.LicenseGrouping} />
				}
				bottomContent={
					<BottomNavigationTemplate
						actionButtonLabel="Neue Zuweisung"
						onActionButtonClick={() => setShowNewRuleModal(true)}
						canGoBack
						onBackClick={handleGoToLicenses}
					/>
				}
			>
				<Wrapper>
					<LicenseInfoWrapper>
						<Breadcrumbs>
							<TkaP3Span tkaColor={shade60} onClick={handleGoToLicenses}>
								Lizenzen{" "}
							</TkaP3Span>
							<Icon icon="arrowRight2" sizeInRem={0.45} />
							<TkaP3Span bold> Details</TkaP3Span>
						</Breadcrumbs>
						<H1Span>Lizenzverwaltung</H1Span>
						{!isMediumUp && (
							<>
								<Spacing32 />
								<LicenseProductCard
									queryFragmentRef={data.LicenseGrouping.LicenseGrouping}
								/>
								<Spacing16 />
							</>
						)}
						<Spacing16 />
						<TkaP1Span>{licenseDescription}</TkaP1Span>
						<StyledButton
							label="Zum Angebot"
							iconName="arrowRight"
							colorVersion="tertiary"
							onClick={handleGoToLearnable}
						/>
					</LicenseInfoWrapper>

					{licenseModel === "FreeForAll" && (
						<Message
							highlighted
							severity="warn"
							content={
								<>
									<TkaH3Span tkaColor={warning100}>
										Zuweisungsregeln haben keinen Effekt
									</TkaH3Span>
									<TkaP2Span tkaColor={warning100}>
										Da die Lizenz-Einstellungen auf "Jeder im Konto kann alle
										Lizenzen im Konto nutzen" gesetzt ist.
									</TkaP2Span>
									<TkaP2Span
										tkaColor={warning100}
										underline
										onClick={handleChangeSettingsOnClick}
									>
										Einstellung ändern
									</TkaP2Span>
								</>
							}
						/>
					)}

					<div>
						<TkaH2Span>Eingelöste Lizenzen</TkaH2Span>
						<LicenseBarContainer>
							<UsedLicensesBar width={usedLicensesPercentage} />
						</LicenseBarContainer>
						<LicenseLabels>
							<LicenseLabel>
								<LegendLicenseBar status={"used"} />
								<TkaP3Span tkaColor={shade80} className="underline">
									{usedLicenses}&times; Eingelöst
								</TkaP3Span>
							</LicenseLabel>
							<LicenseLabel>
								<LegendLicenseBar status={"remaining"} />
								<TkaP3Span tkaColor={shade80} className="underline">
									{remainingLicenses}&times; Verfügbar
								</TkaP3Span>
							</LicenseLabel>
						</LicenseLabels>
					</div>
					<TabBar
						disableMaxParentHeight
						activeIndex={activeIndex}
						onTabChange={handleOnTabChange}
					>
						<TabPanel header={`Gruppenzuweisungen (${groupRules.length})`}>
							<Spacing24 />
							<GroupRulesTab
								items={groupRules}
								groupOptions={availableGroups}
								refetch={refetch}
							/>
						</TabPanel>
						<TabPanel header={`Einzelzuweisungen (${userRules.length})`}>
							<Spacing24 />
							<UserRulesTab
								userFragmentRef={query}
								items={userRules}
								refetch={refetch}
							/>
						</TabPanel>
					</TabBar>
				</Wrapper>
			</ScreenWithNavbarSidemenuLayout>
		</>
	);
};
