import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query learnTrackingList_Query(
		$first: Int
		$last: Int
		$before: String
		$after: String
		$sortByUserLastName: SortOrder
		$filterByText: String
		$filterByMinDate: LocalDate
		$filterByMaxDate: LocalDate
		$filterByGroupIds: [ID!]
		$filterByCompletedCourses: Boolean
	) {
		...learnTrackingList_QueryFragment
			@arguments(
				first: $first
				last: $last
				before: $before
				after: $after
				sortByUserLastName: $sortByUserLastName
				filterByText: $filterByText
				filterByMinDate: $filterByMinDate
				filterByMaxDate: $filterByMaxDate
				filterByGroupIds: $filterByGroupIds
				filterByCompletedCourses: $filterByCompletedCourses
			)
	}
`;

export const LEARN_TRACKING_LIST_QUERY_FRAGMENT = graphql`
	fragment learnTrackingList_QueryFragment on Query
	@refetchable(queryName: "learnTrackingList_QueryFragmentRefetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
		sortByUserLastName: { type: "SortOrder", defaultValue: ASC }
		filterByText: { type: "String", defaultValue: null }
		filterByMinDate: { type: "LocalDate", defaultValue: null }
		filterByMaxDate: { type: "LocalDate", defaultValue: null }
		filterByGroupIds: { type: "[ID!]", defaultValue: null }
		filterByCompletedCourses: { type: "Boolean", defaultValue: false }
	) {
		Viewer {
			Learntracking {
				learntracking(
					first: $first
					last: $last
					before: $before
					after: $after
					sortByUserLastName: $sortByUserLastName
					filterByText: $filterByText
					filterByMinDate: $filterByMinDate
					filterByMaxDate: $filterByMaxDate
					filterByGroupIds: $filterByGroupIds
					filterByCompletedCourses: $filterByCompletedCourses
				) @connection(key: "learnTrackingList_learntracking") {
					edges {
						node {
							...learnTrackingRow_LearnStatesForUserFragment
							learnStates {
								title
							}
							user {
								id
							}
						}
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
