import { FC, useState } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { Button } from "@components/button";
import { Label } from "@components/label";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { useGroupsTabProps } from "@screens/account/account.hooks";
import { TkaH2Span } from "@themes/font-tags";
import useDebounce from "@utils/useDebounce";
import { GroupsTabContext } from "./groups-tab.context";
import { USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT, QUERY_FRAGMENT } from "./groups-tab.graphql";
import {
	BottomBatchEditSpacer,
	ButtonsWrapper,
	LabelsWrapper,
	MembersAndLabelsWrapper,
	SearchWrapper,
	Wrapper,
} from "./groups-tab.styles";
import { SelectedGroup } from "./groups-tab.types";
import { BatchEditGroups } from "./parts/batch-edit-groups";
import { CreateGroupModal } from "./parts/create-group-modal";
import { DeleteGroupsModal } from "./parts/delete-groups-modal/delete-groups-modal.component";
import { GroupsList } from "./parts/groups-list/groups-list.component";
import { HeaderWrapper, SearchBarMobileWrapper } from "../members-tab/members-tab.styles";
import { InviteMemberModal } from "../members-tab/parts/invite-member-modal";
import { RefetchContext } from "../refetch-context/refetch.context";
import { SearchBar } from "../search-bar";
import { SearchBarMobile } from "../search-bar/search-bar-mobile";

//TODO: add-translations
export const GroupsTab: FC = () => {
	const [selectedGroups, setSelectedGroups] = useState<SelectedGroup[]>([]);
	const [numberOfGroups, setNumberOfGroups] = useState<number>(0);
	const { queryFragmentRef, userInAccountGroupQueryFragmentRef } = useGroupsTabProps();

	const userInAccountGroupQueryFragment = useFragment(
		USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT,
		userInAccountGroupQueryFragmentRef ?? null,
	);
	const [_, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const [isVisibleCreateGroupModal, setIsVisibleCreateGroupModal] = useState(false);
	const [searchIsVisibleOnMobile, setSearchIsVisibleOnMobile] = useState(false);
	const [searchByName, setSearchByName] = useState<string>("");
	const [inviteMemberModalIsVisible, setInviteMemberModalIsVisible] = useState(false);

	const debouncedSearchValue = useDebounce(searchByName, 500);

	const [deletableGroupIds, setDeletableGroupIds] = useState<string[]>();

	const { isAccountOwner, canInviteUsersViaCSVUpload, isAVGSAdmin } = usePermissions();
	const { isMediumUp } = useWindowSize();

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		setSearchIsVisibleOnMobile(!searchIsVisibleOnMobile);
	};

	const handleCreateGroupOnClick = () => {
		setIsVisibleCreateGroupModal(true);
	};

	const handleOnDismissCreateGroupModal = () => {
		setIsVisibleCreateGroupModal(false);
	};

	const hanleOnDeleteGroupGroup = (ids: string[]) => {
		setDeletableGroupIds(ids);
	};

	const hanleOnDismissDeleteGroupModal = () => {
		setDeletableGroupIds(undefined);
	};

	const handleCsvOnClick = () => {
		setInviteMemberModalIsVisible(true);
	};

	const handleToggleGroup = (group: SelectedGroup) => {
		const groupIndex = selectedGroups.findIndex(
			(selectedGroup) => selectedGroup.id === group.id,
		);
		if (groupIndex !== -1) {
			const copy = selectedGroups.slice();
			copy.splice(groupIndex, 1);
			setSelectedGroups(copy);
		} else {
			setSelectedGroups([...selectedGroups, group]);
		}
	};

	const handleClearSelectedGroups = () => {
		setSelectedGroups([]);
	};

	const handleDeleteGroupsOnSuccess = () => {
		handleClearSelectedGroups();
		hanleOnDismissDeleteGroupModal();
	};

	const handleDismissInviteMemberModal = () => {
		setInviteMemberModalIsVisible(false);
	};

	const handleSearchOnChange = (text: string) => {
		setSearchByName(text);
	};

	const isBatchEditVisible = selectedGroups.length > 1;

	const handleRefetch = () => {
		refetch({}, { fetchPolicy: "network-only" });
	};

	return (
		<RefetchContext.Provider value={{ refetch: handleRefetch }}>
			<GroupsTabContext.Provider
				value={{
					deletableGroupIds,
					deleteGroupIds: hanleOnDeleteGroupGroup,
					selectedGroups,
					toogleGroup: handleToggleGroup,
					clearSelectedGroups: handleClearSelectedGroups,
					numberOfGroups,
					setNumberOfGroups,
				}}
			>
				<Wrapper>
					<HeaderWrapper>
						<MembersAndLabelsWrapper>
							<TkaH2Span>Gruppen</TkaH2Span>
							<LabelsWrapper>
								<Label label={`${numberOfGroups} Ergebnisse`} />
							</LabelsWrapper>
						</MembersAndLabelsWrapper>
						<SearchWrapper>
							<SearchBar
								value={searchByName}
								onChange={handleSearchOnChange}
								onClick={handleToggleOnClick}
							/>
						</SearchWrapper>
						{searchIsVisibleOnMobile && (
							<SearchBarMobileWrapper>
								<SearchBarMobile
									value={searchByName}
									onChange={handleSearchOnChange}
								/>
							</SearchBarMobileWrapper>
						)}
						{isAccountOwner && canInviteUsersViaCSVUpload ? (
							<ButtonsWrapper>
								<Button
									colorVersion="outline"
									label="CSV Upload"
									onClick={handleCsvOnClick}
								/>
								<Button
									colorVersion="outline"
									label="Erstellen"
									iconName="add"
									onClick={handleCreateGroupOnClick}
								/>
							</ButtonsWrapper>
						) : (
							<ButtonsWrapper>
								<Button
									colorVersion="outline"
									label="Erstellen"
									iconName="add"
									onClick={handleCreateGroupOnClick}
									disabled={!isAccountOwner && !isAVGSAdmin}
								/>
							</ButtonsWrapper>
						)}
					</HeaderWrapper>
					<BatchEditGroups queryFragmentRef={userInAccountGroupQueryFragment} />

					<GroupsList
						searchByName={debouncedSearchValue}
						queryFragmentRef={userInAccountGroupQueryFragment}
					/>

					{isBatchEditVisible && <BottomBatchEditSpacer />}
					<CreateGroupModal
						isVisible={isVisibleCreateGroupModal}
						onDismiss={handleOnDismissCreateGroupModal}
					/>
					<DeleteGroupsModal
						isVisible={!!deletableGroupIds}
						onDismiss={hanleOnDismissDeleteGroupModal}
						onSuccess={handleDeleteGroupsOnSuccess}
					/>
					<InviteMemberModal
						isVisible={inviteMemberModalIsVisible}
						onDismiss={handleDismissInviteMemberModal}
						tabView={false}
						inviteType="csv"
						setInviteMemberModalIsVisible={setInviteMemberModalIsVisible}
					/>
				</Wrapper>
			</GroupsTabContext.Provider>
		</RefetchContext.Provider>
	);
};
