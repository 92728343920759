import { Calendar } from "@components/calendar";
import { ValidatedCalendarProps } from "./validated-calendar.types";

export const ValidatedCalendar = <S,>({
	formikConfig,
	helpText,
	label,
	name,
	placeholder,
	status = "default",
	onChange,
}: ValidatedCalendarProps<S>) => {
	const errorText = formikConfig.errors[name] as unknown as string | undefined;
	const hasError = !!errorText;

	const value = formikConfig.values[name] as unknown as Date | undefined;

	const handleOnChange = (date?: Date) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, date);
		onChange?.(date);
	};

	const useStatus = hasError ? "error" : status;
	const textBelow = hasError ? errorText : helpText;

	return (
		<Calendar
			label={label}
			name={name}
			onChange={handleOnChange}
			placeholder={placeholder}
			smallTextBellow={textBelow}
			status={useStatus}
			value={value}
		/>
	);
};
