/**
 * @generated SignedSource<<cff4162f6d84533c4fbabf6e3486beb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectUserInAccountGroupsField_Query$variables = {
  filterByAccountId: string;
};
export type selectUserInAccountGroupsField_Query$data = {
  readonly Auth: {
    readonly SearchUserInAccountGroupsAsAccountOwnerQuery: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type selectUserInAccountGroupsField_Query = {
  response: selectUserInAccountGroupsField_Query$data;
  variables: selectUserInAccountGroupsField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByAccountId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthQueries",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filterByAccountId",
            "variableName": "filterByAccountId"
          }
        ],
        "concreteType": "UserInAccountGroup",
        "kind": "LinkedField",
        "name": "SearchUserInAccountGroupsAsAccountOwnerQuery",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserInAccountGroupsField_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectUserInAccountGroupsField_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "989ba2cece69b14e3a8a726197da9d4d",
    "id": null,
    "metadata": {},
    "name": "selectUserInAccountGroupsField_Query",
    "operationKind": "query",
    "text": "query selectUserInAccountGroupsField_Query(\n  $filterByAccountId: ID!\n) {\n  Auth {\n    SearchUserInAccountGroupsAsAccountOwnerQuery(filterByAccountId: $filterByAccountId) {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69042600d9a549fe8cf34b94be47a522";

export default node;
