import { match } from "ts-pattern";
import {
	LearnTrackingFilterAction,
	LearnTrackingFilterState,
} from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.types";

export const learnTrackingFiltersReducer = (
	state: LearnTrackingFilterState,
	action: LearnTrackingFilterAction,
): LearnTrackingFilterState => {
	return match(action)
		.with({ type: "setFilterByText" }, ({ value }) => ({ ...state, filterByText: value }))
		.with({ type: "setFilterByMinDate" }, ({ value }) => ({ ...state, filterByMinDate: value }))
		.with({ type: "setFilterByMaxDate" }, ({ value }) => ({ ...state, filterByMaxDate: value }))
		.with({ type: "setFilterByGroupIds" }, ({ value }) => ({
			...state,
			filterByGroupIds: value,
		}))
		.with({ type: "setSortByUserLastName" }, ({ value }) => ({
			...state,
			sortByUserLastName: value,
		}))
		.with({ type: "setFilterByCompletedCourses" }, ({ value }) => ({
			...state,
			filterByCompletedCourses: value,
		}))
		.exhaustive();
};
