import { motion } from "motion/react";
import { useState } from "react";
import { contentClass, headerClass } from "@components/expandable-box/expandable-box.styles";
import { ExpandableBoxProps } from "./expandable-box.types";

export const ExpandableBox = ({
	expandableNode,
	children,
	isOpenable,
	onToggle,
}: ExpandableBoxProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOnToggle = () => {
		if (!isOpenable) return;
		setIsOpen((prev) => !prev);
		onToggle?.();
	};

	return (
		<>
			<div onClick={handleOnToggle} className={headerClass({ isOpenable: isOpenable })}>
				{expandableNode ? expandableNode({ isOpen }) : <></>}
			</div>
			<motion.div
				initial={false}
				animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
				transition={{ duration: 0.3 }}
				className={contentClass}
			>
				{children}
			</motion.div>
		</>
	);
};
