/**
 * @generated SignedSource<<40f2679468480c8e5fe28fcd62aeac3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type learnTrackingRow_LearnStatesForUserFragment$data = {
  readonly averageProgressInPercent: number;
  readonly learnStates: ReadonlyArray<{
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"learnStateRow_LearnStatesFragment">;
  }>;
  readonly user: {
    readonly extension: {
      readonly avatar?: {
        readonly name: string;
        readonly thumbnail: string | null;
        readonly url: string | null;
      } | null;
    } | null;
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "learnTrackingRow_LearnStatesForUserFragment";
};
export type learnTrackingRow_LearnStatesForUserFragment$key = {
  readonly " $data"?: learnTrackingRow_LearnStatesForUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnTrackingRow_LearnStatesForUserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "learnTrackingRow_LearnStatesForUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "userExtensionKind",
              "value": "Academies"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnail",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "AcademiesUserExtension",
              "abstractKey": null
            }
          ],
          "storageKey": "extension(userExtensionKind:\"Academies\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageProgressInPercent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnStates",
      "kind": "LinkedField",
      "name": "learnStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "learnStateRow_LearnStatesFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnStatesForUser",
  "abstractKey": null
};
})();

(node as any).hash = "23603955f86847ec393c3f2ca8b60c77";

export default node;
