import { Color } from "@constellation-academy/epic-ui";
import { Tag } from "primereact/tag";
import styled from "styled-components";
import tw from "twin.macro";
import { LabelSeverity, LabelSize, StyledTagProps } from "./label.types";
import { getBackgroundColor, getClosableBackgroundColor } from "./label.utils";

export const StyledTag = styled(Tag)<StyledTagProps>`
	background-color: ${(props) => props.backgroundColor ?? getBackgroundColor};
	${({ onClick }) => (onClick ? tw`hover:cursor-pointer` : "")}
	${tw`
        p-0
        overflow-hidden
    `}
`;

export const Wrapper = styled.div<{ minWidthRem?: number }>`
	${tw`
		flex
		flex-row
		justify-between
	`}
	${({ minWidthRem }) =>
		minWidthRem ? `min-width: ${minWidthRem}rem; justify-content: space-between;` : ""}
`;

export const IconLabelWrapper = styled.div<{ size: LabelSize; lineThroughColor?: Color }>`
	${({ lineThroughColor }) =>
		lineThroughColor
			? `text-decoration: line-through;  text-decoration-color: ${
					lineThroughColor.rgbaValue() ?? "black"
			  };`
			: ""}
	${({ size }) => (size === "big" ? tw`px-16 py-8` : tw`px-8 py-4`)}
	${tw`flex flex-row items-center gap-4`}
`;

export const ClosableWrapper = styled.div<{ size: LabelSize; labelSeverity: LabelSeverity }>`
	background-color: ${getClosableBackgroundColor};
	${({ size }) => (size === "big" ? tw`p-8` : tw`p-4`)}
	${tw`flex items-center justify-center hover:cursor-pointer`}
`;
