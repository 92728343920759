import { createContext, Dispatch } from "react";
import {
	LearnTrackingFilterAction,
	LearnTrackingFilterState,
} from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.types";

export type LearnTrackingTabContext = {
	filters: LearnTrackingFilterState;
	activeFiltersCount: number;
	dispatch: Dispatch<LearnTrackingFilterAction>;
};

export const LearnTrackingTabContext = createContext<LearnTrackingTabContext>({
	activeFiltersCount: 0,
	filters: {
		filterByText: "",
		filterByMinDate: null,
		filterByMaxDate: null,
		filterByGroupIds: null,
		sortByUserLastName: "ASC",
		filterByCompletedCourses: false,
	},
	dispatch: () => {},
});
