import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const donutWrapperClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "24",
	height: "24",
	borderRadius: "full",
	background:
		"[conic-gradient(var(--colors-secondary-100) var(--progress, 0deg), var(--colors-shade-10) var(--progress, 0deg))]",
});

export const donutInnerClass = css({
	width: "1/2",
	height: "1/2",
	borderRadius: "full",
	backgroundColor: "shade.0",
});
