import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";
import tw from "twin.macro";
import { box, flex } from "@styled-system/patterns";
import { TkaP1Span } from "@themes/font-tags";

export const wrapper = flex({
	position: "relative",
	w: "full",
	h: "full",
	flexDirection: "column",
	overflowY: "hidden",
});

export const contentWrapper = flex({
	flex: "1",
	flexDirection: "column",
	overflowY: "scroll",
});

export const textWrapper = box({
	gap: "12",
});

export const TextSpanWhiteSpacePreLine = tw(TkaP1Span)`
  whitespace-pre-line
  leading-10
`;

export const spacing16 = box({
	mb: "16",
});

export const gapTextWrapper = box({
	display: "inline-block",
	mx: "8",
});

export const FixedBottomWrapper = styled.div<{ bottomPx: number }>`
	${tw`
		xl:hidden
		fixed
		w-full
		z-20
	`}
	bottom: ${({ bottomPx }) => bottomPx}px;
`;

export const relativeBottomWrapper = box({
	visibility: ["hidden", "hidden", "hidden", "visible"],
});

export const answersComponentWrapper = flex({
	backgroundColor: "shade.10",
	p: "24",
	pb: "8",
	flexDirection: "column",
	gap: "12",
});

export const StyledScrollPanel = styled(ScrollPanel)`
	&.p-scrollpanel .p-scrollpanel-content {
		${tw`
			no-scrollbar
			flex
			flex-row
			flex-nowrap
			items-start
			gap-8
		`};
	}
`;
