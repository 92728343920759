import { PathParams } from "@constellation-academy/epic-ui";
import { useFragment, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { Icon } from "@components/icon";
import { scaleQuestion_PotentialAnalysisQuestionFragment$key } from "@relay/scaleQuestion_PotentialAnalysisQuestionFragment.graphql";
import { scaleQuestion_SetAnswerInScaleQuestionMutation } from "@relay/scaleQuestion_SetAnswerInScaleQuestionMutation.graphql";
import { AvgsPotentialAnalysisPath } from "@router/paths";
import { onBrandMain } from "@themes/colors";
import { P3Span } from "@themes/font-tags";
import {
	scaleWrapperClass,
	poleWrapperClass,
	poleClass,
	poleSpacerClass,
	labelClass,
} from "./scala-question.styles";
import {
	POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
	SET_ANSWER_IN_SCALE_QUESTION_MUTATION,
} from "./scale-question.graphql";
import { ScaleQuestionProps } from "./scale-question.types";
import { isQuestionAnswered } from "../avgs-potential-analysis-navigation/avgs-potential-analysis-navigation.utils";

export const ScaleQuestion = ({
	isActive,
	potentialAnalysisQuestionFragmentRef,
}: ScaleQuestionProps) => {
	const { potentialAnalysisId } = useParams<PathParams<typeof AvgsPotentialAnalysisPath>>();

	const question = useFragment<scaleQuestion_PotentialAnalysisQuestionFragment$key>(
		POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
		potentialAnalysisQuestionFragmentRef,
	);

	const [setAnswerInScaleQuestion] = useMutation<scaleQuestion_SetAnswerInScaleQuestionMutation>(
		SET_ANSWER_IN_SCALE_QUESTION_MUTATION,
	);

	const { questionText, id, givenAnswer, answerOptions } = question;

	const createPoleOnClickHandler = (answerOptionId: number) => () => {
		if (!potentialAnalysisId || !id || !questionText || !answerOptions) return;
		setAnswerInScaleQuestion({
			variables: {
				input: {
					learnPotentialAnalysisId: potentialAnalysisId,
					questionId: id,
					questionText,
					answerOptions: answerOptions,
					givenAnswer: {
						answerOptionId,
					},
				},
			},
		});
	};

	const isAnswered = isQuestionAnswered(question);
	const isBlured = !isAnswered && !isActive;

	return (
		<div className={scaleWrapperClass}>
			{answerOptions?.map((answerOption) => {
				const poleNumber = answerOption.text.match(/\(\d\)/gm)?.[0];
				const text = answerOption.text.replace(/\(\d\)/gm, "");
				const selected = givenAnswer?.answerOptionId === answerOption.answerOptionId;
				return (
					<div key={answerOption.answerOptionId} className={poleWrapperClass}>
						<div className={poleSpacerClass}>
							<div
								className={poleClass({ isAnswered, selected, isBlured })}
								onClick={createPoleOnClickHandler(answerOption.answerOptionId)}
							>
								{selected && (
									<Icon icon="tick" sizeInRem={1} tkaColor={onBrandMain} />
								)}
							</div>
						</div>
						<div className={labelClass({ isBlured })}>
							{poleNumber && <P3Span>{poleNumber}</P3Span>}
							<br />
							<P3Span>{text}</P3Span>
						</div>
					</div>
				);
			})}
		</div>
	);
};
