import { motion, AnimatePresence } from "motion/react";
import { useState } from "react";
import {
	getWrapperAnimation,
	iconClass,
	inputAnimation,
	searchBarClass,
} from "@components/extendable-searchbar/extendable-searchbar.styles";
import { ExtendableSearchbarProps } from "@components/extendable-searchbar/extendable-searchbar.types";
import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";

export const ExtendableSearchbar = ({ value, onChange }: ExtendableSearchbarProps) => {
	const [showSearch, setShowSearch] = useState(false);

	const handleShowSearchOnClick = () => {
		setShowSearch((prev) => !prev);
	};

	const handleOnChange = (input?: string) => {
		onChange?.(input ?? "");
	};

	return (
		<motion.div className={iconClass} {...getWrapperAnimation(showSearch)}>
			<Icon icon="search" sizeInRem={1} onClick={handleShowSearchOnClick} />
			<AnimatePresence>
				{showSearch && (
					<motion.div className={searchBarClass} {...inputAnimation}>
						<InputText
							placeholder={"Suchen ..."}
							value={value}
							onChange={handleOnChange}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};
