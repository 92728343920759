import { LearnTrackingFilterState } from "@screens/account/parts/learn-tracking-tab/learn-tracking-tab.types";

export const initialFilterState: LearnTrackingFilterState = {
	filterByText: "",
	filterByMinDate: null,
	filterByMaxDate: null,
	filterByGroupIds: null,
	sortByUserLastName: "ASC",
	filterByCompletedCourses: false,
};
