import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginEmailPassword } from "@components/login-email-password";
import { LoginEmailPasswordFormState } from "@components/login-email-password/login-email-password.interface";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { useAuthContext } from "@hooks/use-auth-context";
import { useToast } from "@hooks/useToast";
import { loginSteps_CreateClickoutByLinkIdMutation } from "@relay/loginSteps_CreateClickoutByLinkIdMutation.graphql";
import { loginSteps_InvitationByTokenQuery } from "@relay/loginSteps_InvitationByTokenQuery.graphql";
import { Path } from "@router/paths";
import { PlacementContext } from "@screens/placement/placement.context";
import { selectRedirectPath } from "@slices/auth/auth.selectors";
import { CREATE_CLICKOUT_BY_LINKID_MUTATION, INVITATION_QUERY } from "./login-steps.graphql";
import { LoginStepsProps } from "./login-steps.types";
import { AuthTemplateStep } from "../auth-template-step";

export const LoginSteps = ({ inline, onGoToSignUp, onGoToForgotPassword }: LoginStepsProps) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const linkId = searchParams.get("linkId");
	const orderIdParam = searchParams.get("orderId");
	const { state } = location;
	const { showError } = useToast();
	const redirectPath = useSelector(selectRedirectPath);
	const { placementLinkId, setPlacementLinkId } = useContext(PlacementContext);

	const navigate = useNavigate();
	const { t } = useTranslation("inviteScreens");

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<loginSteps_InvitationByTokenQuery>(INVITATION_QUERY, {
		token: state?.invitationToken ?? "",
		skip: !state?.invitationToken,
	});

	const [createClickoutByLinkId] = useMutation<loginSteps_CreateClickoutByLinkIdMutation>(
		CREATE_CLICKOUT_BY_LINKID_MUTATION,
	);

	useEffect(() => {
		if (linkId) {
			setPlacementLinkId(linkId);
		}
	}, [linkId]);

	const { login, redirectAfterLogin } = useAuthContext();

	const handlePlacementRedirect = (email: string, password: string) => {
		createClickoutByLinkId({
			variables: {
				input: {
					linkId: placementLinkId,
				},
			},
			onCompleted: (response) => {
				const clickoutURL = response?.Placement.createClickOutByLinkId?.placementUrl;
				if (!clickoutURL) {
					navigate(Path.selectAccount.path, { replace: true });
					showError({
						summary: "Das hat leider nicht geklappt",
						detail: "Weiterleitung fehlgeschlagen",
					});
				}
				window.location.href = clickoutURL!;
			},
			onError: () => {
				login(email, password).then((response) => {
					const loginResult = response.Auth.loginFromWebapp?.loginResult;
					if (loginResult?.isAVGSClient && loginResult.avgsPotentialAnalysisId) {
						handleAVGSPotentialAnalysisRedirect(loginResult.avgsPotentialAnalysisId);
						return;
					}
					navigate(state?.redirect ?? Path.selectAccount.path, { replace: true });
				});
				showError({
					summary: "Das hat leider nicht geklappt",
					detail: "Weiterleitung fehlgeschlagen",
				});
			},
		});
	};

	const handleShoppingCartInvoiceRedirect = (orderId: string) => {
		navigate(Path.shoppingCart.withId(orderId).invoice.path);
	};

	const handleAVGSPotentialAnalysisRedirect = (avgsPotentialAnalysisId: string) => {
		navigate(Path.avgsPotentialAnalysis.withId(avgsPotentialAnalysisId).path);
	};
	const handleAcceptInvitationRedirect = (token: string) => {
		navigate(Path.acceptInvitation.withId(token).path);
	};

	const handleRedirectToLinkUrlAfterLogin = (redirectPath?: string) => {
		if (redirectPath) {
			window.location.href = redirectPath;
		}
	};

	const handleLoginOnSubmit = (values: LoginEmailPasswordFormState) => {
		login(values.email, values.password).then((response) => {
			const token = state?.invitationToken;

			const loginResult = response.Auth.loginFromWebapp?.loginResult;
			if (inline) {
				return;
			}

			const hasNoPlacementId = !placementLinkId;
			const hasRedirectPath = !!redirectPath;
			const hasNoOrderId = !orderIdParam;
			const hasNoToken = !token;
			const isAvgsClientWithPA =
				loginResult?.isAVGSClient && loginResult.avgsPotentialAnalysisId;

			const needsRedirect =
				hasNoPlacementId &&
				hasRedirectPath &&
				hasNoOrderId &&
				hasNoToken &&
				!isAvgsClientWithPA;

			if (placementLinkId) {
				handlePlacementRedirect(values.email, values.password);
				return;
			}
			if (orderIdParam) {
				handleShoppingCartInvoiceRedirect(orderIdParam);
				return;
			}
			if (token) {
				handleAcceptInvitationRedirect(token);
				return;
			}
			if (loginResult?.isAVGSClient && loginResult.avgsPotentialAnalysisId) {
				handleAVGSPotentialAnalysisRedirect(loginResult.avgsPotentialAnalysisId);
				return;
			}
			if (needsRedirect) {
				handleRedirectToLinkUrlAfterLogin(redirectPath);
				return;
			}

			redirectAfterLogin();
		});
	};

	const handleGoToSignUp = () => {
		if (onGoToSignUp) return onGoToSignUp();
		navigate(Path.signup.path, { state });
	};

	const handleGoToForgotPassword = () => {
		if (onGoToForgotPassword) return onGoToForgotPassword();
		navigate(Path.forgotPassword.path);
	};

	const inviteText =
		invitation?.accountName && invitation?.invitingUserName
			? t("invite_screens.invitedByTitle", {
					account: invitation.accountName,
					invitingUser: invitation.invitingUserName,
			  })
			: undefined;

	const step = (
		<LoginEmailPassword
			headerText={inviteText}
			onSubmit={handleLoginOnSubmit}
			onGoToSignUp={handleGoToSignUp}
			onGoToForgotPassword={handleGoToForgotPassword}
		/>
	);

	return inline ? (
		step
	) : (
		<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>{() => step}</MultiStepAuthContainer>
	);
};
