import graphql from "babel-plugin-relay/macro";

export const LEARN_STATES_FRAGMENT = graphql`
	fragment learnStateProgressBar_LearnStatesFragment on LearnStates {
		moduleStates {
			title
			lastEditedTitle
			lastEditedAt

			scores {
				performanceInPercent
				receivedPoints
				totalPoints
			}
		}
	}
`;
