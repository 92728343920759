/**
 * @generated SignedSource<<4f9148546709dd9e32d976a876ef5d17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendOrderConfirmationEmailInput = {
  clientMutationId?: string | null;
  orderId: string;
};
export type shoppingCartSuccess_OrderMutation$variables = {
  input: SendOrderConfirmationEmailInput;
};
export type shoppingCartSuccess_OrderMutation$data = {
  readonly Billing: {
    readonly sendOrderConfirmationEmail: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type shoppingCartSuccess_OrderMutation = {
  response: shoppingCartSuccess_OrderMutation$data;
  variables: shoppingCartSuccess_OrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BillingMutations",
    "kind": "LinkedField",
    "name": "Billing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SendOrderConfirmationEmailPayload",
        "kind": "LinkedField",
        "name": "sendOrderConfirmationEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppingCartSuccess_OrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shoppingCartSuccess_OrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26b0ce1442edeba55bbb0941bf8c8757",
    "id": null,
    "metadata": {},
    "name": "shoppingCartSuccess_OrderMutation",
    "operationKind": "mutation",
    "text": "mutation shoppingCartSuccess_OrderMutation(\n  $input: SendOrderConfirmationEmailInput!\n) {\n  Billing {\n    sendOrderConfirmationEmail(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e723a45b2f4bd132ec3a6ec31cf3055";

export default node;
