import { format } from "date-fns";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "@components/calendar";
import { Dropdown } from "@components/dropdown";
import { Icon } from "@components/icon";
import { InputSwitch } from "@components/input-switch";
import { Modal } from "@components/modal";
import { useWindowSize } from "@hooks/use-window-size";
import { LearnTrackingTabContext } from "@screens/account/parts/learn-tracking-tab";
import {
	inputSwitchClass,
	dividerClass,
	filtersWrapperClass,
	filterModalContentClass,
	closeButtonClass,
	flexWrapperClass,
	headerClass,
	closeIconClass,
	sortOrderButtonClass,
} from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-filters/learn-tracking-filters.styles";
import { LearnTrackingFiltersProps } from "@screens/account/parts/learn-tracking-tab/parts/learn-tracking-filters/learn-tracking-filters.types";
import { selectCurrentAccountId } from "@slices/auth/auth.selectors";
import { B1Span, H2Span, P2Span, P3Span } from "@themes/font-tags";
import { SelectUserInAccountGroupsField } from "../../../../../../features/accounts/select-user-in-account-groups-field";

export const LearnTrackingFilters = ({ onHide, isVisible }: LearnTrackingFiltersProps) => {
	const { filters, dispatch } = useContext(LearnTrackingTabContext);
	const currentAccountId = useSelector(selectCurrentAccountId) ?? "";

	const handleFilterByMinDateOnChange = (value?: Date) => {
		dispatch({
			type: "setFilterByMinDate",
			value: value ? format(value, "yyyy-MM-dd") : null,
		});
	};

	const handleFilterByMaxDateOnChange = (value?: Date) => {
		dispatch({
			type: "setFilterByMaxDate",
			value: value ? format(value, "yyyy-MM-dd") : null,
		});
	};

	const handleSortByUserLastNameOnChange = () => {
		const newOrder = filters.sortByUserLastName === "ASC" ? "DESC" : "ASC";
		dispatch({ type: "setSortByUserLastName", value: newOrder });
	};

	const handleSortByCompletedOnlyOnChange = () => {
		const newValue = !filters.filterByCompletedCourses;
		dispatch({ type: "setFilterByCompletedCourses", value: newValue });
	};

	const handleRemoveMinDateSelection = () => {
		dispatch({ type: "setFilterByMinDate", value: null });
	};

	const handleRemoveMaxDateSelection = () => {
		dispatch({ type: "setFilterByMaxDate", value: null });
	};

	const handleGroupIdsChange = (value: string[] | undefined) => {
		dispatch({
			type: "setFilterByGroupIds",
			value: value && value.length > 0 ? value : null,
		});
	};

	const sortByDropdownOptions = [{ label: "Name", value: "name" }];

	const { isMediumUp } = useWindowSize();

	return (
		<Modal onHide={onHide} isVisible={isVisible} position={isMediumUp ? "center" : "bottom"}>
			<div className={filterModalContentClass}>
				<div className={headerClass}>
					<H2Span>Filter</H2Span>
					<div className={closeIconClass}>
						<Icon icon="close" sizeInRem={0.75} onClick={onHide} />
					</div>
				</div>
				<div className={filtersWrapperClass}>
					<div>
						<P3Span>Von</P3Span>
						<div className={flexWrapperClass}>
							<Calendar
								onChange={handleFilterByMinDateOnChange}
								value={
									filters.filterByMinDate
										? new Date(filters.filterByMinDate)
										: undefined
								}
								placeholder={"TT.MM.JJJJ"}
							/>
							<Icon
								icon="close"
								sizeInRem={1}
								onClick={handleRemoveMinDateSelection}
							/>
						</div>
					</div>
					<div>
						<P3Span>Bis</P3Span>
						<div className={flexWrapperClass}>
							<Calendar
								onChange={handleFilterByMaxDateOnChange}
								value={
									filters.filterByMaxDate
										? new Date(filters.filterByMaxDate)
										: undefined
								}
								placeholder={"TT.MM.JJJJ"}
							/>
							<Icon
								icon="close"
								sizeInRem={1}
								onClick={handleRemoveMaxDateSelection}
							/>
						</div>
					</div>
					<div>
						<P3Span>Gruppen</P3Span>
						<SelectUserInAccountGroupsField
							value={filters.filterByGroupIds}
							placeholder={""}
							accountId={currentAccountId}
							onChange={handleGroupIdsChange}
						/>
					</div>
					<div>
						<P3Span>Sortiert nach</P3Span>
						<div className={inputSwitchClass}>
							<Dropdown
								options={sortByDropdownOptions}
								value={sortByDropdownOptions[0].value}
							/>
							<div className={sortOrderButtonClass}>
								<Icon
									icon={
										filters.sortByUserLastName === "ASC"
											? "keyboardArrowUp"
											: "keyboardArrowDown"
									}
									sizeInRem={1}
									onClick={handleSortByUserLastNameOnChange}
								/>
							</div>
						</div>
					</div>
					<div className={dividerClass} />
					<div className={filtersWrapperClass}>
						<div className={inputSwitchClass}>
							<P2Span>Zeige nur abgeschlossen</P2Span>
							<InputSwitch
								checked={filters.filterByCompletedCourses}
								onChange={handleSortByCompletedOnlyOnChange}
							/>
						</div>
					</div>
				</div>
				<B1Span className={closeButtonClass} onClick={onHide}>
					Schließen
				</B1Span>
			</div>
		</Modal>
	);
};
