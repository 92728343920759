import { ZonedDateTime } from "@js-joda/core";
import { EpicL1Span } from "@constellation-academy/epic-ui";
import { Suspense, useEffect, useState } from "react";
import { useFragment, useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { BottomNavigationTemplateProps } from "@components/bottom-navigation-template/bottom-navigation-template.types";
import { Button } from "@components/button";
import { LicenseModal } from "@components/license-modal";
import { OrderIhkFlow } from "@components/order-ihk-flow";
import { useCurrentPath } from "@hooks/use-current-path";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { advanceTreeNavigation_BookTreeMutation } from "@relay/advanceTreeNavigation_BookTreeMutation.graphql";
import { advanceTreeNavigation_LearnOpportunityV2Fragment$key } from "@relay/advanceTreeNavigation_LearnOpportunityV2Fragment.graphql";
import { advanceTreeNavigation_Query } from "@relay/advanceTreeNavigation_Query.graphql";
import { advanceTreeNavigation_RestartContentNodeAfterFailedMutation } from "@relay/advanceTreeNavigation_RestartContentNodeAfterFailedMutation.graphql";
import { advanceTreeNavigation_RestartContentNodeAfterPassedMutation } from "@relay/advanceTreeNavigation_RestartContentNodeAfterPassedMutation.graphql";
import { advanceTreeNavigation_StartContentNodeMutation } from "@relay/advanceTreeNavigation_StartContentNodeMutation.graphql";
import { advanceTreeNavigation_StartTreeMutation } from "@relay/advanceTreeNavigation_StartTreeMutation.graphql";
import { Path } from "@router/index";
import { ResidenceDropdownOptions } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";
import { formatCurrency } from "@utils/currency";
import { formatRelativeTime } from "@utils/date-utils";
import {
	BOOK_TREE_MUTATION,
	QUERY,
	RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
	RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
	START_CONTENT_NODE_MUTATION,
	START_TREE_MUTATION,
	LEARN_OPPORTUNITY_V2_FRAGMENT,
} from "./advance-tree-navigation.graphql";
import { AdvanceTreeNavigationSkeleton } from "./advance-tree-navigation.skeleton";
import { BottomTextWrapper, Wrapper } from "./advance-tree-navigation.styles";
import { AdvanceTreeNavigationProps } from "./advance-tree-navigation.types";
import { gtmTrackStartTrial } from "../../analytics/google-tag-manager";

// TODO: add-translations
export const AdvanceTreeNavigationWithPreloadedQuery = ({
	queryRef,
	onRefresh,
}: AdvanceTreeNavigationProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const { rootId, branchNodeId: contentNodeId } = useParams();
	const navigate = useNavigate();

	const query = usePreloadedQuery(QUERY, queryRef);

	const node = useFragment<advanceTreeNavigation_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		query.node,
	);

	const userCountryCode: ResidenceDropdownOptions =
		query.AccountBaseData.AccountBaseData.countryCode;
	const taxWording = userCountryCode === "AT" ? "USt." : "MwSt.";

	const [showHasLicenseModal, setShowHasLicenseModal] = useState(false);

	const [startTree] = useMutation<advanceTreeNavigation_StartTreeMutation>(START_TREE_MUTATION);

	const [bookTree] = useMutation<advanceTreeNavigation_BookTreeMutation>(BOOK_TREE_MUTATION);

	const [startContentNode] = useMutation<advanceTreeNavigation_StartContentNodeMutation>(
		START_CONTENT_NODE_MUTATION,
	);

	const [restartContentNodeAfterPassed] =
		useMutation<advanceTreeNavigation_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterFailed] =
		useMutation<advanceTreeNavigation_RestartContentNodeAfterFailedMutation>(
			RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
		);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const currentPath = useCurrentPath();
	const { isXLargeUp } = useWindowSize();

	const structureDefinition = node?.root?.structureDefinition;
	const rootExtension = node?.root?.structureDefinition.extension;
	const viewerTreeState = structureDefinition?.viewerTreeState;
	const advancementResultStatus = node?.typeDefinition.contentNodeAdvancementResult?.status;
	const contentKind = node?.typeDefinition.contentKind;

	const availableLicensesCount = rootExtension?.extension?.licenseAvailability?.numAvailable ?? 0;
	const netPrice = rootExtension?.product?.netPrice;
	const hasLicenses = availableLicensesCount > 0;
	const availableLicensesText = `${availableLicensesCount} x Lizenz${
		availableLicensesCount > 1 ? "en" : ""
	} verfügbar`;

	const canOrderIHK = rootExtension?.ihkState === "ReadyToOrder";
	const isAtHead = viewerTreeState?.headContentId === contentNodeId;

	const rootStartedAt = structureDefinition?.viewerTreeState?.startedAt;

	const handleStartTreeOnClick = () => {
		if (!rootId) return;
		if (rootExtension?.unlockInfo?.kind === "Demo") {
			gtmTrackStartTrial(rootId);
		}
		startTree({
			variables: {
				input: {
					nodeId: rootId,
				},
			},
			onCompleted: onRefresh,
		});
	};

	const handleGoToFirstLessonOnClick = () => {
		if (!rootId) return;
		navigate(Path.root.withId(rootId).branchNodes.path, { replace: true });
	};

	const handleGoToLessonOnClick = (nextNodeId: string) => {
		if (!rootId) return;
		const path = Path.root.withId(rootId).branchNodes.withId(nextNodeId);
		if (isXLargeUp) {
			navigate(path.path, { replace: true });
		} else {
			navigate(path.lessonOverview.path);
		}
	};

	const handleGoToNextLessonOnClick = () => {
		if (!viewerTreeState?.headContentId) return;
		handleGoToLessonOnClick(viewerTreeState?.headContentId);
	};

	const handleBuyLicenseOnClick = () => {
		if (!rootExtension?.cartClickout?.relativeLink) return;
		navigate(rootExtension?.cartClickout?.relativeLink);
	};

	const handleShowModal = () => {
		setShowHasLicenseModal(true);
	};

	const handleLicenseModalOnDismiss = () => {
		setShowHasLicenseModal(false);
	};

	const handleUseLicenseOnClick = () => {
		if (!rootId) return;
		setShowHasLicenseModal(false);
		bookTree({
			variables: {
				input: {
					rootId,
				},
			},
			onCompleted: onRefresh,
		});
	};

	const handleOrderIHKOnClick = () => {
		setIsModalVisible(true);
	};

	const handleModalOnDismiss = () => {
		setIsModalVisible(false);
	};

	const handleStartContentNodeOnClick = () => {
		if (!contentNodeId) return;
		startContentNode({
			variables: {
				input: {
					contentNodeId,
				},
			},
			onCompleted: (response) => {
				const id = response.LearnV2.startContentNode?.contentSubmission.id;
				id && navigate(Path.contentSubmission.withId(id).path);
			},
		});
	};

	const handleRestartContentNodeAfterPassedOnClick = () => {
		if (!contentNodeId) return;
		restartContentNodeAfterPassed({
			variables: {
				input: {
					contentNodeId,
				},
			},
			onCompleted: (response) => {
				const id = response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id;
				id && navigate(Path.contentSubmission.withId(id).path);
			},
		});
	};

	const handleRestartContentNodeAfterFailedOnClick = () => {
		if (!contentNodeId) return;
		restartContentNodeAfterFailed({
			variables: {
				input: {
					contentNodeId,
				},
			},
			onCompleted: (response) => {
				const id = response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id;
				id && navigate(Path.contentSubmission.withId(id).path);
			},
		});
	};

	const { templateProps } = ((): {
		templateProps: BottomNavigationTemplateProps;
	} => {
		if (!viewerTreeState || viewerTreeState.kind === "CanNotBeStarted") {
			return {
				templateProps: {
					actionButtonDisabled: true,
					actionButtonLabel: t("course_screen.startTree"),
					actionButtonIconName: "arrowRight",
				},
			};
		}
		if (viewerTreeState.kind === "CanBeStarted") {
			let buttonLabel;

			if (hasLicenses) {
				buttonLabel = "Los geht's";
			} else if (rootExtension?.unlockInfo?.kind === "Demo") {
				buttonLabel = "Kostenlos starten";
			} else {
				buttonLabel = t("course_screen.startTree");
			}

			return {
				templateProps: {
					actionButtonLabel: buttonLabel,
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleStartTreeOnClick,
				},
			};
		}

		const isOverviewPath =
			currentPath?.route.path ===
			Path.root.withIdPlaceholder().branchNodes.withIdPlaceholder().lessonOverview.path;

		if (!contentNodeId || (!isXLargeUp && !isOverviewPath)) {
			if (viewerTreeState.kind === "IsFinished") {
				return {
					templateProps: {
						actionButtonLabel: "Lehrgang wiederholen",
						actionButtonIconName: "synchronizeArrowClock",
						onActionButtonClick: handleGoToFirstLessonOnClick,
					},
				};
			}
			return {
				templateProps: {
					actionButtonLabel: t("course_screen.toNextLesson"),
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleGoToNextLessonOnClick,
				},
			};
		}

		const hasFullAccess = rootExtension?.unlockInfo?.kind === "FullAccess";
		const needsLicense = contentNodeId === rootExtension?.unlockInfo?.firstContentId;

		if (advancementResultStatus === "CanNotBeStarted" && needsLicense && !hasFullAccess) {
			if (!isAtHead) {
				return {
					templateProps: {
						actionButtonLabel: t("course_screen.toNextLesson"),
						actionButtonIconName: "arrowRight",
						onActionButtonClick: handleGoToNextLessonOnClick,
					},
				};
			}
			if (hasLicenses) {
				return {
					templateProps: {
						actionButtonLabel: "Lizenz einlösen",
						actionButtonIconName: "arrowRight",
						actionButtonColorVersion: "strong",
						onActionButtonClick: handleShowModal,
						bottomText: availableLicensesText,
					},
				};
			}
			return {
				templateProps: {
					actionButtonLabel: "Lizenz erwerben",
					actionButtonIconName: "shoppingBasket",
					onActionButtonClick: handleBuyLicenseOnClick,
					bottomNode: !hasLicenses ? (
						<BottomTextWrapper>
							<EpicL1Span>
								<EpicL1Span>{formatCurrency(netPrice)}</EpicL1Span>&nbsp;
								{`zzgl. ${taxWording}`}
							</EpicL1Span>
						</BottomTextWrapper>
					) : undefined,
				},
			};
		}

		if (advancementResultStatus === "CanBeStarted") {
			const startLabel =
				contentKind === "Async"
					? t("course_screen.startAsyncContent")
					: t("course_screen.startLesson");
			return {
				templateProps: {
					actionButtonLabel: startLabel,
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleStartContentNodeOnClick,
				},
			};
		}

		if (
			advancementResultStatus === "CanBeRestartedAfterFailed" ||
			advancementResultStatus === "CanBeRestartedAfterPassed"
		) {
			const onClickHandler =
				advancementResultStatus === "CanBeRestartedAfterPassed"
					? handleRestartContentNodeAfterPassedOnClick
					: handleRestartContentNodeAfterFailedOnClick;

			if (viewerTreeState.kind === "IsFinished" || isAtHead) {
				return {
					templateProps: {
						actionButtonLabel: t("course_screen.repeatLesson"),
						actionButtonIconName: "synchronizeArrowClock",
						onActionButtonClick: onClickHandler,
					},
				};
			}
			return {
				templateProps: {
					actionButtonLabel: t("course_screen.toNextLesson"),
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleGoToNextLessonOnClick,
					secondaryButton: (
						<Button
							iconName="synchronizeArrowClock"
							label={t("course_screen.repeatLesson")}
							colorVersion="tertiary"
							onClick={onClickHandler}
						/>
					),
				},
			};
		}

		const nextContentNodeId = node?.nextContentNodeId;

		if (
			advancementResultStatus === "CanNotBeRestartedAfterFailed" ||
			advancementResultStatus === "CanNotBeRestartedAfterPassed"
		) {
			if (isAtHead) {
				return {
					templateProps: {
						actionButtonDisabled: true,
						actionButtonLabel: t("course_screen.repeatLesson"),
						actionButtonIconName: "synchronizeArrowClock",
					},
				};
			} else if (viewerTreeState.kind === "IsFinished") {
				if (nextContentNodeId) {
					return {
						templateProps: {
							actionButtonLabel: t("course_screen.toNextLesson"),
							actionButtonIconName: "arrowRight",
							onActionButtonClick: () => handleGoToLessonOnClick(nextContentNodeId),
						},
					};
				} else {
					return {
						templateProps: {
							actionButtonLabel: "Lehrgang wiederholen",
							actionButtonIconName: "synchronizeArrowClock",
							onActionButtonClick: handleGoToFirstLessonOnClick,
						},
					};
				}
			}
			return {
				templateProps: {
					actionButtonLabel: t("course_screen.toNextLesson"),
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleGoToNextLessonOnClick,
				},
			};
		}

		if (viewerTreeState.kind !== "IsFinished" && !isAtHead) {
			return {
				templateProps: {
					actionButtonLabel: t("course_screen.toNextLesson"),
					actionButtonIconName: "arrowRight",
					onActionButtonClick: handleGoToNextLessonOnClick,
				},
			};
		}
		const reason = node?.typeDefinition.contentNodeAdvancementResult?.reasonConfigTypes;
		if (
			advancementResultStatus === "CanNotBeStarted" &&
			reason?.includes("StartContent_CanAfterStartingTree") &&
			rootStartedAt
		) {
			const parseDate = ZonedDateTime.parse(rootStartedAt);
			const formatedRelativeDays = formatRelativeTime(parseDate.plusDays(7));
			const label = `wird ${formatedRelativeDays} freigeschaltet`;
			return {
				templateProps: {
					actionButtonLabel: label,
					actionButtonIconName: "arrowRight",
					actionButtonDisabled: true,
				},
			};
		}

		if (nextContentNodeId) {
			return {
				templateProps: {
					actionButtonLabel: t("course_screen.toNextLesson"),
					actionButtonIconName: "arrowRight",
					onActionButtonClick: () => handleGoToLessonOnClick(nextContentNodeId),
				},
			};
		} else {
			return {
				templateProps: {
					actionButtonLabel: "Lehrgang wiederholen",
					actionButtonIconName: "synchronizeArrowClock",
					onActionButtonClick: handleGoToFirstLessonOnClick,
				},
			};
		}
	})();

	const useTemplateProps =
		canOrderIHK && (viewerTreeState?.kind === "IsFinished" || !isAtHead)
			? ({
					actionButtonLabel: "IHK-Zertifikat beantragen",
					actionButtonIconName: "arrowRight",
					actionButtonColorVersion: "strong",
					onActionButtonClick: handleOrderIHKOnClick,
					secondaryButton: (
						<Button
							disabled={templateProps.actionButtonDisabled}
							label={templateProps.actionButtonLabel}
							colorVersion="tertiary"
							onClick={templateProps.onActionButtonClick}
						/>
					),
			  } as BottomNavigationTemplateProps)
			: templateProps;

	return (
		<Wrapper>
			<LicenseModal
				isVisible={showHasLicenseModal}
				onDismiss={handleLicenseModalOnDismiss}
				onBuyClick={handleUseLicenseOnClick}
				variant="hasLicense"
			/>
			<BottomNavigationTemplate {...useTemplateProps} />
			{rootId && (
				<OrderIhkFlow
					rootId={rootId}
					isVisible={isModalVisible}
					onDismiss={handleModalOnDismiss}
					baseDataFragmentRef={query.AccountBaseData.AccountBaseData}
				/>
			)}
		</Wrapper>
	);
};

export const AdvanceTreeNavigation = () => {
	const { rootId, branchNodeId } = useParams();
	const [queryReference, loadQuery] = useQueryLoader<advanceTreeNavigation_Query>(QUERY);

	const updateQuery = () => {
		const queryId = branchNodeId ? branchNodeId : rootId;
		queryId && loadQuery({ id: queryId }, { fetchPolicy: "store-and-network" });
	};

	useEffect(() => {
		updateQuery();
	}, [loadQuery, branchNodeId, rootId]);

	const handleOnRefresh = () => {
		updateQuery();
	};

	return (
		<Suspense fallback={<AdvanceTreeNavigationSkeleton />}>
			{queryReference && (
				<AdvanceTreeNavigationWithPreloadedQuery
					queryRef={queryReference}
					onRefresh={handleOnRefresh}
				/>
			)}
		</Suspense>
	);
};
