import graphql from "babel-plugin-relay/macro";

export const LEARN_STATES_FRAGMENT = graphql`
	fragment learnStateRow_LearnStatesFragment on LearnStates {
		title
		startedAt
		progressInPercent
		scores {
			performanceInPercent
			totalPoints
			receivedPoints
		}
		...learnStateProgressBar_LearnStatesFragment
	}
`;
