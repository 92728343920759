import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const headerClass = flex({
	justifyContent: "space-between",
	alignItems: "center",
	mb: "16",
});
export const closeIconClass = css({
	mdDown: {
		display: "none",
	},
});
export const filterModalContentClass = stack({
	backgroundColor: "shade.0",
	borderRadius: "24",
});
export const filtersWrapperClass = stack({
	gap: "12",
	md: { width: "[15rem]" },
	mdDown: { pt: "24" },
});
export const closeButtonClass = stack({
	color: "secondary.120",
	fontWeight: "bold",
	cursor: "pointer",
	textAlign: "center",
	py: "12",
	md: {
		display: "none",
	},
});
export const inputSwitchClass = flex({
	justifyContent: "space-between",
	alignItems: "center",
	width: "full",
	gap: "8",
	"& > :first-child": {
		flex: "1",
	},
});
export const sortOrderButtonClass = css({
	borderRadius: "4!",
	border: "1px solid",
	borderColor: "shade.40!",
	p: "10",
});
export const flexWrapperClass = flex({
	gap: "8",
	alignItems: "center",
	"& > :first-child": {
		flex: "1",
	},
});

export const dividerClass = css({
	my: "24!",
	borderColor: "shade.10",
	border: "1px solid",
});
