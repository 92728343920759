import { PathParams } from "@constellation-academy/epic-ui";
import { useEffect, useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { MaterialsTree } from "@components/materials-tree";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { useWindowSize } from "@hooks/use-window-size";
import { MaterialsPath, Path, RootPath } from "@router/paths";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./materials-tab.graphql";
import { ModalWrapper, TabWrapper } from "./materials-tab.styles";
import { MaterialsTabProps } from "./materials-tab.types";
import { MaterialOverview } from "../material-overview";

export const MaterialsTab = ({ learnOpportunityV2FragmentRef }: MaterialsTabProps) => {
	const learnOpportunityV2 = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const { materialId, rootId } = useParams<PathParams<typeof MaterialsPath | typeof RootPath>>();
	const navigate = useNavigate();
	const { isXLargeUp } = useWindowSize();

	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		if (isXLargeUp && isModalVisible) {
			setIsModalVisible(false);
		}
	}, [isXLargeUp]);

	const handleAttachmentOnClick = (attachmentId: string) => {
		rootId &&
			navigate(Path.root.withId(rootId).materials.withId(attachmentId).path, {
				replace: true,
			});
		if (!isXLargeUp) {
			setIsModalVisible(true);
		}
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	return (
		<TabWrapper>
			{learnOpportunityV2 && (
				<MaterialsTree
					activeAttachmentId={materialId}
					learnOpportunityV2FragmentRef={learnOpportunityV2}
					onAttachmentOnClick={handleAttachmentOnClick}
				/>
			)}
			<ResponsiveBottomSheetModal isVisible={isModalVisible} onDismiss={handleOnDismiss}>
				<ModalWrapper>
					<MaterialOverview showDetails />
				</ModalWrapper>
			</ResponsiveBottomSheetModal>
		</TabWrapper>
	);
};
