import { ModuleProgress } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-progress-bar/learn-state-progress-bar.types";

export const MODULE_PROGRESS = {
	COMPLETED: "completed",
	IN_PROGRESS: "inProgress",
	NOT_STARTED: "notStarted",
} as const;

export const MODULE_PROGRESS_TRANSLATIONS: Record<ModuleProgress, string> = {
	[MODULE_PROGRESS.COMPLETED]: "Abgeschlossen",
	[MODULE_PROGRESS.IN_PROGRESS]: "In Bearbeitung",
	[MODULE_PROGRESS.NOT_STARTED]: "Nicht gestartet",
};
