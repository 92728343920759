import { PathBase, PathWithId, PathBaseWithId, Path } from "@constellation-academy/epic-ui";

export class ProfilePath extends PathBase {
	static readonly pathKey = "profile";
}

export class VocabularyPath extends PathBase {
	static readonly pathKey = "vocabulary";
}

export class MyAvailabilitiesPath extends PathBase {
	static readonly pathKey = "myAvailabilities";
	static readonly pathName = "my-availabilities";
}

export class MyCoachingOffersPath extends PathBase {
	static readonly pathKey = "myCoachingOffers";
	static readonly pathName = "my-coaching-offers";
}

export class LicenseDetailsCreateSinglePath extends PathBase {
	static readonly pathKey = "createSingle";
	static readonly pathName = "create-single";
}
export class LicenseDetailsCreateGroupPath extends PathBase {
	static readonly pathKey = "createGroup";
	static readonly pathName = "create-group";
}
export class LicenseDetailsPath extends PathWithId<typeof LicenseDetailsPath> {
	static readonly pathKey = "detail";
	static readonly idKey = "licenseId";
	static readonly childPaths = [LicenseDetailsCreateSinglePath, LicenseDetailsCreateGroupPath];
}
export class LicensesPath extends Path<typeof LicensesPath> {
	static readonly pathKey = "licenses";
	static readonly childPaths = [LicenseDetailsPath];
}

export class AccountDataPath extends PathBase {
	static readonly pathKey = "accountData";
	static readonly pathName = "account-data";
}
export class MembersPath extends PathBase {
	static readonly pathKey = "members";
}
export class GroupsPath extends PathBaseWithId {
	static readonly pathKey = "groups";
	static readonly idKey = "groupId";
}
export class LearnTrackingPath extends PathBase {
	static readonly pathKey = "learntracking";
}

export class AccountPath extends Path<typeof AccountPath> {
	static readonly pathKey = "account";
	static readonly childPaths = [AccountDataPath, MembersPath, GroupsPath, LearnTrackingPath];
}

export class SettingsPath extends PathBase {
	static readonly pathKey = "settings";
}

export class ImprintPath extends PathBase {
	static readonly pathKey = "imprint";
}

export class ProfileMenuPath extends Path<typeof ProfileMenuPath> {
	static readonly pathKey = "profileMenu";
	static readonly pathName = "profile-menu";
	static readonly childPaths = [
		ProfilePath,
		VocabularyPath,
		MyAvailabilitiesPath,
		MyCoachingOffersPath,
		LicensesPath,
		AccountPath,
		SettingsPath,
		ImprintPath,
	];
}
