import { Tooltip, TooltipPosition } from "@constellation-academy/epic-ui";
import { StyledTooltipProps } from "@components/styled-tooltip/styled-tooltip.types";
import { tooltipClass } from "@screens/account/parts/learn-tracking-tab/parts/learn-state-row/learn-state-row.styles";
import { H4Span, P3Span } from "@themes/font-tags";

export const StyledTooltip = ({
	tooltipContent,
	target,
	position = TooltipPosition.Top,
}: StyledTooltipProps) => {
	return (
		<Tooltip target={target} position={position}>
			<div className={tooltipClass}>
				<H4Span>{tooltipContent.title}</H4Span>
				<P3Span>{tooltipContent.content}</P3Span>
			</div>
		</Tooltip>
	);
};
