import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PathParams } from "@constellation-academy/epic-ui";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, useFragment } from "react-relay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";
import { withSuspense } from "@components/with-suspense";
import { shoppingCartOverview_OrderFragment$key } from "@relay/shoppingCartOverview_OrderFragment.graphql";
import { shoppingCartOverview_Query } from "@relay/shoppingCartOverview_Query.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { selectIsLoggedIn } from "@slices/auth/auth.selectors";
import { H1Span } from "@themes/font-tags";
import { QUERY, ORDER_FRAGMENT } from "./shopping-cart-overview.graphql";
import { ScreenWrapper } from "./shopping-cart-overview.styles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

//TODO: add-translations
export const ShoppingCartOverviewScreenComponent = () => {
	const location = useLocation();
	const { state: clientSecret } = location;

	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();

	const {
		node,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartOverview_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
		},
		{ fetchPolicy: "network-only" },
	);

	const order = useFragment<shoppingCartOverview_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const navigate = useNavigate();

	const handlePreviousOnClick = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (!isLoggedIn) {
			navigate(Path.shoppingCart.withId(order.id).invoice.path);
		}
	}, [isLoggedIn]);

	const options = clientSecret;

	return (
		<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
			<ShoppingCartTemplate
				onPrevious={handlePreviousOnClick}
				authViewerSchemaFragmentRef={Auth}
				orderFragmentRef={order}
				showNextButton={false}
			>
				<ScreenWrapper>
					<H1Span>Übersicht & Zahlung</H1Span>
					<EmbeddedCheckout />
				</ScreenWrapper>
			</ShoppingCartTemplate>
		</EmbeddedCheckoutProvider>
	);
};

export const ShoppingCartProductsScreen = withSuspense(
	ShoppingCartOverviewScreenComponent,
	ShoppingCartTemplateSkeleton,
);

export const ShoppingCartOverviewScreenWithPaymentContext = () => {
	return <ShoppingCartProductsScreen />;
};
